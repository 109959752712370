import React from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';

import classnames from 'classnames/bind';
import style from './DragScroller.module.scss';

const cx = classnames.bind(style);

type DragScroller = {
  classes?: string,
  children: React.ReactNode,
};

export default function DragScroller({
  classes = '',
  children,
}: DragScroller): JSX.Element {
  return (
    <ScrollContainer className={cx('drag-scroller', classes)}>
      {children}
    </ScrollContainer>
  );
}
