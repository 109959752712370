import * as React from 'react';
import generateClasses from 'app/shared/utils/generateClasses';

import css from './Flex.module.scss';

type FlexProps = {
  wrap?: ('wrap' | 'nowrap') | boolean,
  direction?: 'col' | 'row',
  justify?: 'justify-center' | 'justify-start' | 'justify-end' | 'justify-between' | 'justify-around',
  items?: 'items-center' | 'items-start' | 'items-end',
  margin?: string,
  padding?: string,
  children?: React.ReactNode
};

const Flex: React.SFC<FlexProps> = ({
  children,
  wrap,
  direction,
  justify,
  items,
  margin,
  padding,
}) => (
  <div
    className={generateClasses(css, 'flex', {
      wrap,
      direction,
      justify,
      items,
      margin,
      padding,
    })}
  >
    {children}
  </div>
);
Flex.defaultProps = {
  children: null,
  wrap: undefined,
  direction: undefined,
  justify: undefined,
  margin: undefined,
  padding: undefined,
  items: undefined,
};
export default Flex;
