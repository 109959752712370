import * as React from 'react';
import autobind from 'autobind-decorator';
import Terms from 'app/shared/components/Terms';
import { Modal, Flex, Button, H4 } from 'app/components';

import css from './TermsOfService.module.scss';

/** Definitions */
class TermsOfService extends React.PureComponent<{}, TermsOfServiceState> {
  static defaultProps = {
    children: null,
  };

  state = {
    isVisible: false,
  };

  @autobind
  toggle(): void {
    this.setState({ isVisible: !this.state.isVisible });
  }

  render(): React.ReactNode {
    return (
      <div className={css.terms}>
        <div role="button" tabIndex={-1} onClick={this.toggle} className={css['terms-trigger']}>
          {this.props.children}
        </div>
        <Modal isVisible={this.state.isVisible} onClose={this.toggle}>
          <Modal.Title>
            <H4 align="left">Termos de uso</H4>
          </Modal.Title>
          <Modal.Body>
            <div className={css['terms-body']}>
              <Terms />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Flex justify="justify-end">
              <Button onClick={this.toggle}>Fechar</Button>
            </Flex>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

/** Definitions */
type TermsOfServiceState = {
  isVisible: boolean;
  children?: React.ReactNode;
};

export default TermsOfService;
