// Internal
import { appTypes } from 'app/shared/utils/actions';

const avatar = appTypes.defineAction('avatar');

// eslint-disable-next-line import/prefer-default-export
export const AVATAR = avatar.defineAction('AVATAR', [
  'UPLOAD',
  'DELETE',
  'REMOVE',
  'FINISH',
  'ERROR',
]);
