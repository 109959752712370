import * as React from 'react';
import uuid from 'uuid/v4';
import autobind from 'autobind-decorator';
import generateClasses from 'app/shared/utils/generateClasses';
import { Dropdown, Label, Caption, Input } from 'app/components';

import css from './Select.module.scss';
import { SelectOptionsFragment } from './typings';

/** Component */
class Select extends React.PureComponent<SelectProps, SelectState> {
  static Option: any = () => null;

  static defaultProps = {
    options: [],
    type: 'text',
    children: null,
    onBlur: () => {},
    filterable: false,
    onChange: () => {},
  };

  state = {
    selected: this.props.value,
    filterable: this.props.filterable,
    query: '',
  };

  @autobind
  onType(e) {
    this.setState({ query: e.target.value });
  }

  @autobind
  // eslint-disable-next-line class-methods-use-this
  getSelectedOption(data, value) {
    const selected = data.find(option => option.value === value);
    return selected || {};
  }

  @autobind
  search(data) {
    return data.filter(option =>
      option.label.toLowerCase().includes(this.state.query.toLowerCase()),
    );
  }

  @autobind
  select(option, close) {
    this.setState({ query: '' }, () => {
      if (this.props.onChange) {
        this.props.onChange(
          {
            target: {
              value: option.value,
              name: this.props.name,
            },
          },
          (e) => {
            if (this.props.onBlur) this.props.onBlur(e);
          },
        );
      }
    });
    close();
  }

  @autobind
  Filter() {
    return (
      <div className={css['select-filter']}>
        <Input
          placeholder="buscar..."
          type="text"
          value={this.state.query}
          onChange={this.onType}
        />
      </div>
    );
  }
  render() {
    const { value, kind, name, message, label, placeholder } = this.props;
    const { Filter } = this;
    const options = (React.Children.toArray(this.props.children) as SelectOptionsFragment[]).map(
      option => ({
        label: option.props.label,
        value: option.props.value,
      }),
    );
    const selected = this.getSelectedOption(options, value);
    return (
      <div className={css.select}>
        <Dropdown>
          <Dropdown.Trigger>
            {({ onToggle }) => (
              <div className={generateClasses(css, 'select-trigger', { kind })}>
                {label && (
                  <Label kind={kind} for={name}>
                    {label}
                  </Label>
                )}
                <div
                  className={generateClasses(css, 'select-form-field', {
                    kind,
                  })}
                >
                  <div
                    tabIndex={0}
                    role="button"
                    onClick={onToggle}
                    className={css['select-fake-input']}
                  >
                    {selected.label || <Caption kind="subdued">{placeholder}</Caption>}
                  </div>
                  <div className={css['select-icon']}>
                    <svg style={{ width: '10px' }} viewBox="0 0 1792 1792">
                      <path d="M1408 1088q0 26-19 45l-448 448q-19 19-45 19t-45-19l-448-448q-19-19-19-45t19-45 45-19h896q26 0 45 19t19 45zm0-384q0 26-19 45t-45 19h-896q-26 0-45-19t-19-45 19-45l448-448q19-19 45-19t45 19l448 448q19 19 19 45z" />
                    </svg>
                  </div>
                </div>
                <div className={css['select-message']}>
                  {message && (
                    <Caption padding="p-xs" kind={kind || 'subdued'}>
                      {message}
                    </Caption>
                  )}
                </div>
              </div>
            )}
          </Dropdown.Trigger>
          <Dropdown.Menu>
            {({ onClose }) => (
              <div className={generateClasses(css, 'select-menu', { kind })}>
                {this.state.filterable && <Filter />}
                <div className={css['select-options']}>
                  {this.search(options).map(option => (
                    <div
                      key={uuid()}
                      tabIndex={-1}
                      role="menuitem"
                      className={css['select-item']}
                      onClick={() => {
                        this.select(option, onClose);
                      }}
                    >
                      {option.label}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }
}

/** Definitions */
type SelectProps = {
  name?: string;
  value?: string | number;
  placeholder?: string;
  message?: string;
  label?: string;
  onChange?: (...args: any[]) => any;
  onBlur?: (...args: any[]) => any;
  filterable?: boolean;
  kind?: any;
  children?: React.ReactNode;
};

type SelectState = {
  selected: any;
  filterable: any;
  query: any | string;
};

export default Select;
