export const getDayZeros = (date: Date):string => date.getDate().toString().padStart(2, '0');
export const getMonthZeros = (date: Date):string => (date.getMonth() + 1).toString().padStart(2, '0');
export const isBefore = (date: Date, dateToCompare: Date): boolean => date.getTime() < dateToCompare.getTime();
export const isEqual = (date: Date, dateToCompare: Date): boolean => date.getTime() === dateToCompare.getTime();
export const removeSeconds = (timeString: string): string => timeString.substring(0, 5);

export default class DateFormat {
  date: Date;

  constructor(date?: string | Date) {
    if (date instanceof Date) {
      this.date = date;
    } else if (typeof date === 'string') {
      this.date = date.length > 0 ? new Date(date.replace(/-/gi, '/')) : new Date();
    } else {
      this.date = new Date()
    }
  }

  monthNames = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];

  dayNames = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'];

  getMonthName(abbreviated = false) {
    const monthIndex = this.date.getMonth();
    return abbreviated ? this.monthNames[monthIndex].substring(0, 3) : this.monthNames[monthIndex];
  }

  getDayWeekName(abbreviated = false) {
    const dayIndex = this.date.getDay();
    return abbreviated ? this.dayNames[dayIndex].substring(0, 3) : this.dayNames[dayIndex];
  }

  getDay() {
    return this.date.getDate();
  }

  getMonth() {
    const day = (this.date.getMonth() + 1).toString();
    return day.length < 2 ? '0' + day : day;
  }

  getYear() {
    return this.date.getFullYear().toString().slice(2);
  }

  getFullYear() {
    return this.date.getFullYear();
  }

  addDays(daysCount) {
    const nextDate = new Date(this.date.valueOf());
    nextDate.setDate(this.date.getDate() + daysCount);
    this.date = nextDate;
    return this;
  }

  toString(format = 'YYYY-MM-DD') {
    return format
      .replace(/Y{4}/, this.date.getFullYear().toString())
      .replace(/M{2}/, getMonthZeros(this.date))
      .replace(/D{2}/, getDayZeros(this.date));
  }

  clone() {
    return new Date(this.date.getTime());
  }

  toDate() {
    return this.date;
  }
}
