import * as React from 'react';
import { Flex, RadioInput, Caption } from 'app/components';

import { RadioGroupInterface, RadioOptionsFragment } from './typings';

/** Component */
const RadioGroup: RadioGroupInterface<RadioGroupProps> = ({
  name,
  kind,
  message,
  vertical,
  children,
  onChange,
}) => (
  <Flex direction={vertical ? 'col' : 'row'}>
    {(React.Children.toArray(children) as RadioOptionsFragment[]).map((option, key) => (
      <RadioInput
        key={key}
        name={name}
        onChange={onChange}
        value={option.props.value}
        label={option.props.children}
        checked={option.props.checked}
      />
    ))}
    {message && (
      <Caption padding="p-xs" kind={kind || 'subdued'}>
        {message}
      </Caption>
    )}
  </Flex>
);

/** Fragments */
RadioGroup.Option = () => null;

/** Definitions */
type RadioGroupProps = {
  name: string;
  kind?: string;
  message?: string;
  vertical?: boolean;
  checked?: boolean;
  children?: React.ReactNode;
  onChange?: (e: React.ChangeEvent) => any;
};

RadioGroup.defaultProps = {
  checked: false,
  vertical: false,
  onChange: () => {},
};
export default RadioGroup;
