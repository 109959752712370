import * as React from 'react';
import { Label, Caption } from 'app/components';
import generateClasses from 'app/shared/utils/generateClasses';

import css from './TextareaField.module.scss';

/** Component */
const TextareaField: React.SFC<TextareaFieldProps> = ({
  onChange,
  onBlur,
  value,
  kind,
  name,
  message,
  label,
  placeholder,
  rows,
}) => (
  <div className={generateClasses(css, 'textarea-field', { kind })}>
    {label && (
      <Label kind={kind} for={name}>
        {label}
      </Label>
    )}
    <textarea
      id={name}
      rows={rows}
      name={name}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      className={css.textarea}
      placeholder={placeholder}
    />
    {message && (
      <Caption padding="p-xs" kind={kind || 'subdued'}>
        {message}
      </Caption>
    )}
  </div>
);

/** Definitions */
type TextareaFieldProps = {
  kind?: string;
  rows?: number;
  name?: string;
  value?: string;
  label?: string;
  message?: string;
  placeholder?: string;
  onBlur?: (e: React.FocusEvent) => any;
  onChange?: (e: React.ChangeEvent) => any;
};

TextareaField.defaultProps = {
  rows: 8,
  onBlur: () => {},
  onChange: () => {},
};

export default TextareaField;
