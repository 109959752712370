import { NOTIFICATION } from './types';

export const notificationAdd = (payload) => ({
  type: NOTIFICATION.ADD,
  payload,
});
export const notificationRemove = (id) => ({
  type: NOTIFICATION.REMOVE,
  payload: id,
});
