// External
import React from 'react';

// Internal
import classnames from 'classnames/bind';
import ss from './Toggle.module.scss';
import CHANGE_TAB_RECURRENCE from './constants';

const cx = classnames.bind(ss);

type ToggleProps = {
  classes?: string;
  toggleStatus: (CHANGE_TAB_RECURRENCE: boolean) => void;
  isPerson: boolean;
  disabledTele?: boolean;
  disabledPerson?: boolean;
};

export default function Toggle(
  {
    toggleStatus,
    isPerson,
    disabledTele,
    disabledPerson,
  }: ToggleProps,
): JSX.Element {
  return (
    <div className={ss['switch-form']}>
      <button
        type="button"
        className={
          cx(ss.button,
            { [ss.selected]: isPerson },
            { [ss.disabled]: disabledPerson })
        }
        onClick={() => toggleStatus(CHANGE_TAB_RECURRENCE.accepts_in_person)}
      >
        Presencial
      </button>
      <button
        type="button"
        className={
          cx(ss.button,
            { [ss.selected]: !isPerson },
            { [ss.disabled]: disabledTele })
        }
        onClick={() => toggleStatus(CHANGE_TAB_RECURRENCE.accepts_telemedicine)}
      >
        Teleconsulta
      </button>
    </div>
  );
}
