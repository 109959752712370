import React, { ReactNode } from 'react';
import generateClasses from 'app/shared/utils/generateClasses';
import extractFragments from 'app/shared/utils/extractFragments';

import css from './Card.module.scss';
import { CardInterface, CardFragmentTypes } from './typing';

/** Component */
const Card: CardInterface<CardProps> = ({ children, title, body, footer, padding, border }) => {
  const { Main, Body, Title, Footer }: CardFragmentTypes = extractFragments(children);
  return (
    <div className={generateClasses(css, 'card', { padding, border })}>
      {(Title || title) && <div className={css['card-title']}>{Title || title}</div>}
      {(Body || Main || body) && <div className={css['card-body']}>{Body || Main || body}</div>}
      {(Footer || footer) && <div className={css['card-footer']}>{Footer || footer}</div>}
    </div>
  );
};

/** Fragments */
interface FragmentProps {
  children: ReactNode,
}

Card.Title = ({ children }: FragmentProps) => children;
Card.Title.displayName = 'Title';

Card.Body = ({ children }: FragmentProps) => children;
Card.Body.displayName = 'Body';

Card.Footer = ({ children }: FragmentProps) => children;
Card.Footer.displayName = 'Footer';

/** Definitions */
type CardProps = {
  border?: string;
  padding?: string;
  children?: React.ReactNode;
  body?: string | React.ReactNode;
  title?: string | React.ReactNode;
  footer?: string | React.ReactNode;
};

Card.defaultProps = {
  border: 'none',
  children: null,
};

export default Card;
