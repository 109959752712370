import * as React from 'react';
import generateClasses from 'app/shared/utils/generateClasses';

import css from './H2.module.scss';

/** Component */
const H2: React.SFC<H2Props> = ({ children, kind, size, align, padding, margin }) => (
  <h2 className={generateClasses(css, 'h2', { kind, size, align, padding, margin })}>{children}</h2>
);

/** Definitions */
type H2Props = {
  kind?: string;
  align?: string;
  size?: 'xl' | 'lg' | 'md' | 'sm' | 'heading' | 'body';
  margin?: string;
  padding?: string;
  children?: React.ReactNode;
};

H2.defaultProps = {
  children: null,
};

export default H2;
