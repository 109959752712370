import React from 'react';
import { Flex, Text } from 'app/components';

const Terms = () => (
  <Flex direction="col">
    <Text bold kind="ink" align="center" margin="m-bottom-lg">
      Usuários do Perfil Profissional Online iClinic
    </Text>
    <Text kind="subdued" align="justify" margin="m-bottom-lg">
      Este Termo e Condições Gerais (“Termo”) aplicam-se ao uso dos serviços oferecidos pela iClinic
      Desenvolvimento de Software Ltda., sociedade devidamente inscrita no CNPJ/MF sob o nº
      20.432.039/0001-95 (“ICLINIC”), gestora do Perfil Profissional Online iClinic.
    </Text>
    <Text kind="subdued" align="justify" margin="m-bottom-lg">
      Os serviços disponíveis da ICLINIC, através de acesso aos domínios www.iclinic.com.br e
      www.agendarconsulta.com, bem como todos os seus subdomínios e aplicativos para os sistemas
      operacionais móveis iOS e Android, serão regidos pelas cláusulas e condições abaixo.
    </Text>
    <Text kind="subdued" align="justify" margin="m-bottom-lg">
      Ao aceitar eletronicamente o presente Termo, através do clique no botão “Aceito os Termos de
      Uso” da página de cadastro complementar a esta, o USUÁRIO/PACIENTE estará automaticamente
      aderindo e concordando em se submeter integralmente aos termos e condições e de quaisquer de
      suas alterações futuras, além de aceitar as disposições das políticas do SITE.
    </Text>

    <Text bold kind="ink" align="left" margin="m-top-lg">
      Cláusula 1ª – Das Definições:
    </Text>
    <Text kind="subdued" align="justify" margin="m-y-lg">
      <Text underline>USUÁRIO</Text>: PESSOA FÍSICA, maior e capaz, que exerça a profissão de médico
      ou demais áreas de saúde, que tenha preenchido o{' '}
      <Text underline>PERFIL PROFISSIONAL ONLINE</Text>, cujos dados passam a ser parte integrante
      deste Termo, bem como que tenha "aceitado" eletronicamente todas as cláusulas do mesmo e todas
      as demais políticas disponíveis no site.
    </Text>
    <Text kind="subdued" align="justify" margin="m-y-lg">
      <Text underline>PERFIL PROFISSIONAL ONLINE</Text>: ficha com os dados pessoais e profissionais
      do USUÁRIO, obrigatoriamente preenchida com o Login e a Senha do USUÁRIO para utilização do
      PERFIL sem qualquer custo para o USUÁRIO.
    </Text>
    <Text kind="subdued" align="justify" margin="m-y-lg">
      <Text underline>PACIENTE</Text>: PESSOA FÍSICA, maior e capaz ou seu representante legal, que
      tenha preenchido o CADASTRO PACIENTE, cujos dados passam a ser parte integrante deste Termo,
      bem como que tenha "aceitado" eletronicamente todas as cláusulas do mesmo e todas as demais
      políticas disponíveis no site, com o objetivo de localizar profissionais da área médica,
      podendo solicitar agendamento de consulta online, quando o profissional (USUÁRIO) estiver
      ativado a ferramenta de DISPONIBILIZAÇÃO DO CALENDÁRIO PARA AGENDAMENTO ONLINE DE CONSULTAS.
    </Text>
    <Text kind="subdued" align="justify" margin="m-y-lg">
      <Text underline>CADASTRO PACIENTE</Text>: ficha com os dados pessoais do PACIENTE para
      realizar o AGENDAMENTO ONLINE DE CONSULTAS, quando a ferramenta estiver devidamente ativada
      pelo USUÁRIO.
    </Text>

    <Text kind="subdued" align="justify" margin="m-y-lg">
      <Text underline>CALENDÁRIO PARA AGENDAMENTO ONLINE DE CONSULTAS</Text>: ferramenta
      disponibilizada apenas para usuários com plano ativo na Plataforma ICLINIC que pode ser
      contratada pelo USUÁRIO, profissional da saúde, para disponibilizar as datas e horários em que
      se encontra disponível para a marcação de consultas. O agendamento online da consulta é feito
      pelo PACIENTE e deve ser aceito pelo USUÁRIO para que seja efetivado.
    </Text>

    <Text kind="subdued" align="justify" margin="m-y-lg">
      <Text underline>LOGIN</Text>: trata-se do e-mail, informado pelo USUÁRIO no ato do seu
      CADASTRO;
    </Text>

    <Text kind="subdued" align="justify" margin="m-y-lg">
      <Text underline>SENHA</Text>: sequência de letras e números escolhida pelo USUÁRIO, composta
      de no mínimo 8 caracteres, a qual deverá ser previamente informada pelo USUÁRIO quando do
      acesso ao Perfil Profissional Online ICLINIC, sendo que trocas periódicas das SENHAS no
      período máximo de 60 (sessenta) dias são altamente recomendadas pela ICLINIC, por questões de
      segurança. A ICLINIC ressalta que não terá acesso a esta SENHA, portanto se o USUÁRIO quiser
      recuperar seu acesso, a ICLINIC encaminhará um e-mail para o endereço previamente cadastrado
      para, a partir deste, gerar uma nova SENHA. Sendo assim, com este procedimento, a senha poderá
      ser alterada a qualquer momento pelo USUÁRIO;
    </Text>

    <Text kind="subdued" align="justify" margin="m-y-lg">
      <Text underline>ASSINATURA</Text>: plano para a contratação do serviço de CALENDÁRIO PARA
      AGENDAMENTO ONLINE DE CONSULTAS, realizada após o CADASTRO e, por razões técnicas, possível
      apenas para USUÁRIOS que possuam plano ativo na PLATAFORMA ICLINIC, para que o USUÁRIO possa
      usufruir da ferramenta “Agendamento Online” no PERFIL da ICLINIC, o USUÁRIO poderá também ter
      que efetuar um Cadastro no site do parceiro da ICLINIC (“Terceiro”) que será utilizado para
      efetuar os pagamentos referentes aos serviços da ICLINIC, caso o sistema de pagamento não seja
      já integrado diretamente com o Perfil Profissional Online ICLINIC;
    </Text>

    <Text kind="subdued" align="justify" margin="m-y-lg">
      <Text underline>MENSALIDADE</Text>: valor devido pelo USUÁRIO que queira utilizar o serviço de
      CALENDÁRIO PARA AGENDAMENTO ONLINE DE CONSULTAS;
    </Text>

    <Text kind="subdued" align="justify" margin="m-y-lg">
      <Text underline>SITE</Text>: portal eletrônico da ICLINIC, localizado no endereço{' '}
      <a href="https://www.agendarconsulta.com/" rel="noopener noreferrer">
        www.agendarconsulta.com
      </a>{' '}
      e seus subdomínios, por meio do qual o USUÁRIO poderá acessar o Perfil Profissional Online
      ICLINIC e, caso possua plano ativo na Plataforma ICLINIC, solicitar a disponibilização do
      SERVIÇO DE DISPONIBILIZAÇÃO DO CALENDÁRIO PARA AGENDAMENTO ONLINE DE CONSULTAS, mediante
      preenchimento de CADASTRO, informação de LOGIN e SENHA de acesso próprio. Por meio do SITE o
      PACIENTE poderá visualizar os perfis profissionais cadastrados, bem como agendar consultas de
      forma online, caso o USUÁRIO possua a ferramenta de agendamento ativada.
    </Text>

    <Text bold kind="ink" align="left" margin="m-y-lg">
      Cláusula 2ª – Do Objeto:
    </Text>
    <Text kind="subdued" align="justify" margin="m-y-lg">
      2.1. Constitui objeto do presente Termo a disponibilização, pela ICLINIC, de uma página na web
      contendo o perfil profissional do USUÁRIO, sem custo algum para a utilização do Perfil
      Profissional Online, de modo que suas informações pessoais e profissionais possam ser
      visualizadas por qualquer pessoa que acesse a mencionada página na web, de forma a divulgar
      sua atuação como um todo, sua especialidade, cursos de extensão, especialização, mestrado e
      doutorado, dentre outros. O USUÁRIO ainda poderá, caso assine o SERVIÇO DE DISPONIBILIZAÇÃO DO
      CALENDÁRIO PARA AGENDAMENTO ONLINE DE CONSULTAS, disponibilizar os dias e horários disponíveis
      para agendamento de consultas, permitindo que o PACIENTE agende, de forma online, a sua
      consulta.
    </Text>

    <Text kind="subdued" align="justify" margin="m-y-lg">
      2.2. Em que pese ser passível de utilização a partir de qualquer parte do mundo, o USUÁRIO
      declara-se ciente e concorda que, independentemente do local de onde estejam sendo acessados
      os serviços, a relação entre o USUÁRIO e a ICLINIC estará sempre, e em qualquer hipótese,
      sujeita à legislação brasileira.
    </Text>

    <Text kind="subdued" align="justify" margin="m-y-lg">
      2.3. O CADASTRO dos USUÁRIOS e PACIENTES é gratuito e vigorará por prazo indeterminado,
      podendo ser encerrado pela ICLINIC a qualquer momento independente de aviso prévio.
    </Text>

    <Text kind="subdued" align="justify" margin="m-y-lg">
      2.4. Quanto ao pagamento das MENSALIDADES referentes ao CALENDÁRIO PARA AGENDAMENTO ONLINE DE
      CONSULTAS, cujo acesso tenha sido feito através da ICLINIC, ressalta-se que é utilizado os
      meios de pagamentos de Terceiros (meios de pagamento) para efetuar a transação. Neste caso, a
      ICLINIC compartilhará informações sobre as transações comerciais com o Terceiro e permitirá
      que os USUÁRIOS possam, dentro do ambiente indicado de uso da ICLINIC, autorizar o Terceiro a
      realizar os débitos e transferências necessárias.
    </Text>

    <Text kind="subdued" align="justify" margin="m-y-lg">
      2.5. Caso uma pessoa, não atuante na área médica se cadastre como USUÁRIO, a ICLINIC poderá,
      sem qualquer notificação prévia, retirar o mencionado CADASTRO de seu banco de dados.
    </Text>

    <Text kind="subdued" align="justify" margin="m-y-lg">
      2.6. O PACIENTE poderá visualizar todos os Perfis Profissionais cadastrados no SITE, bem como
      efetuar agendamento de consultas com os USUÁRIOS que possuam acesso ao serviço de
      disponibilização do Calendário para Agendamento Online de Consultas; ainda, poderá publicar
      avaliações e opiniões sobre os USUÁRIOS, quando disponível.
    </Text>

    <Text kind="subdued" align="justify" margin="m-y-lg">
      2.7. A ICLINIC poderá remover avaliações e opiniões que viole os termos de uso ou disposições
      legais ou considerados ofensivos.
    </Text>

    <Text bold kind="ink" align="left" margin="m-y-lg">
      Cláusula 3ª – Do Uso do Serviço de Disponibilização do Calendário para Agendamento Online de
      Consultas:
    </Text>
    <Text kind="subdued" align="justify" margin="m-y-lg">
      3.1. Para habilitação e utilização do Calendário para Agendamento Online de Consultas, o
      USUÁRIO deverá possuir um plano ativo na Plataforma ICLINIC, deverá, ainda acessar o SITE
      utilizando seu LOGIN e SENHA e clicar no ícone “Habilitar agendamento online”, informando à
      ICLINIC todos os dados exigidos, inclusive seu número de registro no CPF, e
      responsabilizando-se o USUÁRIO civil e criminalmente pela veracidade das informações,
      inclusive perante terceiros, obrigando-se também a manter seus dados atualizados.
    </Text>

    <Text kind="subdued" align="justify" margin="m-y-lg">
      3.1.1. O Perfil Profissional Online ICLINIC, bem como o Calendário para Agendamento Online de
      Consultas, estará à disposição do USUÁRIO 24 (vinte e quatro) horas por dia, 7 (sete) dias por
      semana, com um nível de serviço de no mínimo 98,97% (noventa e oito vírgula noventa e sete por
      cento) por ano do tempo de disponibilidade, com exceção de manutenções regulares, problemas
      com os data centers, que são terceirizados, casos fortuitos e de força maior, que serão
      informados previamente mediante comunicado no próprio SITE.
    </Text>

    <Text kind="subdued" align="justify" margin="m-y-lg">
      3.1.2. O LOGIN e a SENHA que dão acesso ao CADASTRO do USUÁRIO são de seu uso pessoal e
      intransferível, razão pela qual a ICLINIC não se responsabiliza por eventual manipulação não
      autorizada dessas informações por terceiros, devendo, portanto, o USUÁRIO tomar todas as
      medidas necessárias para manter em sigilo as referidas informações.
    </Text>

    <Text kind="subdued" align="justify" margin="m-y-lg">
      3.1.3. O USUÁRIO compromete-se a notificar a ICLINIC imediatamente, mediante apresentação da
      reclamação diretamente à ICLINIC, a respeito de qualquer uso não autorizado de sua conta, bem
      como o acesso não autorizado por terceiros à mesma. O USUÁRIO será o único responsável pelas
      operações efetuadas em sua conta, uma vez que o acesso à mesma só será possível mediante a
      aposição da SENHA, cujo conhecimento é exclusivo do USUÁRIO.
    </Text>

    <Text kind="subdued" align="justify" margin="m-y-lg">
      3.1.4. A ICLINIC disponibilizará suporte técnico a seus USUÁRIOS, em horário comercial, via
      telefone, chat e e-mail: suporte@iclinic.com.br. Considera-se horário comercial das 8 horas
      até às 18 horas. Dentro desse horário, as respostas serão realizadas com a maior brevidade
      possível, em poucos minutos, mas excepcionalmente, poderá ocorrer situações em que a resposta
      seja efetuada em até três horas. Ainda, não haverá atendimento de suporte técnico nos feriados
      nacionais, estaduais do Estado de São Paulo e nos feriados municipais da cidade de Ribeirão
      Preto, SP, não havendo, no entanto, impedimento ao USUÁRIO de envio do chat e de e-mail ao
      setor de suporte técnico. Nesse caso, as respostas aos e-mails enviados ao setor de suporte
      técnico serão disponibilizadas ao USUÁRIO em até 2 dias úteis pelo chat e 5 dias úteis,
      contados do envio.
    </Text>

    <Text kind="subdued" align="justify" margin="m-y-lg">
      3.2. A ICLINIC se reserva o direito de utilizar todos os meios válidos e possíveis para
      identificar seus USUÁRIOS, portanto poderá a ICLINIC, a qualquer momento, a seu critério,
      solicitar cópias de documentos do USUÁRIO, de forma a garantir a veracidade dos dados. Nestes
      casos, a ICLINIC poderá suspender o Perfil Profissional, até o recebimento dos documentos
      solicitados, ficando isento de qualquer responsabilidade ou ressarcimento ao pretendente. Caso
      algum dado seja verificado pela ICLINIC como inconsistente com os documentos apresentados, o
      USUÁRIO terá o prazo de 2 (dois) dias úteis para efetuar as devidas correções, sob pena de ter
      o seu acesso ao Perfil Profissional Online ICLINIC bloqueado, até a regularização do CADASTRO,
      podendo inclusive cancelar definitivamente o referido CADASTRO, se assim entender necessário à
      proteção dos interesses da ICLINIC e seus USUÁRIOS, sem prejuízo de outras medidas que
      entender necessárias e oportunas.
    </Text>

    <Text kind="subdued" align="justify" margin="m-y-lg">
      3.3. A ICLINIC não será responsabilizada por erros, interrupções, mal-funcionamentos, atrasos
      ou outras imperfeições que possam surgir nos serviços, que são possíveis de ocorrer em se
      tratando de informática, em especial, considerando o hardware, softwares diversos de terceiros
      e qualidade do serviço de internet do USUÁRIO, não garantindo, a ICLINIC, de nenhuma forma, a
      prestação de serviço de forma ininterrupta e/ou isenta de erros, exceto na forma prevista na
      Cláusula 3.1.1 acima.
    </Text>

    <Text kind="subdued" align="justify" margin="m-y-lg">
      3.4. O USUÁRIO será integralmente responsável por certificar-se de que a configuração de seu
      equipamento está em pleno acordo com os requisitos mínimos, necessários a fruição dos serviços
      oferecidos pela ICLINIC, estando esta última livre e isenta de qualquer responsabilidade
      decorrente da não observância do disposto nesta cláusula.
    </Text>

    <Text bold kind="ink" align="left" margin="m-y-lg">
      Cláusula 4ª – Da Mensalidade:
    </Text>
    <Text kind="subdued" align="justify" margin="m-y-lg">
      4.1. Em razão da utilização do Serviço de Disponibilização do Calendário para Agendamento
      Online de Consultas, o USUÁRIO pagará mensalmente à ICLINIC, de forma antecipada, por meio de
      cartão de crédito, ou qualquer outro meio de pagamento online disponibilizado ao USUÁRIO pela
      ICLINIC, o valor previsto na página de planos da ICLINIC.
    </Text>

    <Text kind="subdued" align="justify" margin="m-y-lg">
      4.1.1. Considerando que a Disponibilização do Calendário para Agendamento Online de Consultas
      é um serviço complementar aos já oferecidos pela ICLINIC, assim que o SERVIÇO é contratado, o
      primeiro pagamento será imediato e proporcional entre a data da nova solicitação e a data base
      do serviço anteriormente assinado, de modo que os próximos vencimentos ocorrerão no mesmo dia
      de vencimento que o USUÁRIO já possuía. A assinatura é pré-paga, ou seja, paga e usa. Após o
      ciclo finalizado da primeira MENSALIDADE, o USUÁRIO autoriza desde já a renovação automática
      da sua ASSINATURA, mediante aceite do presente termo.
    </Text>

    <Text kind="subdued" align="justify" margin="m-y-lg">
      4.1.2. Caso o USUÁRIO deseje cancelar ou não deseje a renovação automática, este deverá
      comunicar através de ligação à ICLINIC, cujo número se encontra em seu SITE, o seu desejo de
      cancelamento ou da não renovação, que será efetuado em até 72 (setenta e duas) horas a contar
      do pedido, sendo que, se feito após a cobrança da MENSALIDADE, a mesma não será reembolsada (o
      acesso ao SERVIÇO somente será bloqueado ao final do uso da última MENSALIDADE paga).
    </Text>

    <Text kind="subdued" align="justify" margin="m-y-lg">
      4.2. A ICLINIC efetuará 9 (nove) tentativas de cobrança no cartão de crédito informado pelo
      USUÁRIO, uma a cada 2 (dois) dias. Se a ICLINIC não lograr efetuar a devida cobrança da
      MENSALIDADE, o bloqueio do SERVIÇO será realizado automaticamente e o atraso no pagamento dos
      valores acordados prevista acima implicará na incidência de multa de 2% (dois por cento), além
      de correção monetária e juros de mora de 1% (um por cento) ao mês. Será facultado à ICLINIC a
      suspensão do acesso ao SERVIÇO até que este quite os valores pendentes. A ICLINIC se reserva o
      direito de tomar as medidas judiciais e extrajudiciais pertinentes para receber os valores
      devidos.
    </Text>

    <Text kind="subdued" align="justify" margin="m-y-lg">
      4.3. Os valores para acesso ao SERVIÇO, a serem pagos conforme previsto no presente Termo,
      serão reajustadas automaticamente a cada 12 (doze) meses, contados do ato de HABILITAÇÃO DO
      SERVIÇO, de acordo com o IGP-M acumulado dos últimos 12 meses ou, na sua ausência, com o
      índice que vier a substituí-lo, independentemente de prévia notificação ao USUÁRIO. Caso o
      IGP-M seja negativo o valor da mensalidade permanecerá inalterado.
    </Text>

    <Text kind="subdued" align="justify" margin="m-y-lg">
      4.4. Caso sejam criados tributos ou alteradas as condições de cálculo e/ou cobrança de
      tributos já existentes, que venham a impactar nos valores de remuneração vigentes, os custos
      resultantes de tal impacto serão repassados ao USUÁRIO e somados à remuneração vigente, de
      forma a restabelecer o equilíbrio econômico-financeiro da prestação dos serviços.
    </Text>

    <Text kind="subdued" align="justify" margin="m-y-lg">
      4.5. O SERVIÇO está totalmente integrado a algum meio de pagamento digital, assim o USUÁRIO
      poderá efetuar o pagamento da MENSALIDADE escolhida para acesso ao SERVIÇO diretamente no
      ambiente da ICLINIC. Com a confirmação do pagamento, o acesso ao SERVIÇO será processado em
      até 72 (setenta e duas) horas.
    </Text>

    <Text bold kind="ink" align="left" margin="m-y-lg">
      Cláusula 5ª – Das Modificações no Presente Termo:
    </Text>

    <Text kind="subdued" align="justify" margin="m-y-lg">
      5.1. A ICLINIC poderá alterar, a qualquer tempo, as condições do presente Termo, visando seu
      aprimoramento e melhoria dos serviços prestados. As novas condições entrarão em vigor 10 (dez)
      dias após sua publicação no SITE. Sendo assim, caso o USUÁRIO não concorde com as modificações
      publicadas, este deverá imediatamente cessar o uso do Perfil Profissional Online ICLINIC e/ou
      do SERVIÇO e proceder com o cancelamento de suas informações, conforme procedimentos descritos
      no presente Termo. Neste caso, o vínculo contratual do USUÁRIO com a ICLINIC deixará de
      existir, desde que não haja contas ou dívidas em aberto. No entanto, não havendo manifestação
      no prazo acima estipulado, entender-se-á que o USUÁRIO aceitou tacitamente as novas condições
      cujo Termo continuará vinculando as partes.
    </Text>

    <Text bold kind="ink" align="left" margin="m-y-lg">
      Cláusula 6ª – Dos Produtos e Práticas Proibidos:
    </Text>
    <Text kind="subdued" align="justify" margin="m-y-lg">
      6.1. Está expressamente proibida pelo presente Termo, pelas demais políticas da ICLINIC, ou
      pela lei vigente, a inserção de quaisquer informações pelo USUÁRIO no Perfil Profissional
      Online ICLINIC ou pelo PACIENTE no campo avaliações e opiniões que contenham dados, imagens
      e/ou legendas que versem sobre ou que façam apologia a: (i) quaisquer atividades ilícitas,
      e/ou vedadas à comunidade médica ou de outros profissionais de saúde; (ii) propriedades
      roubadas, furtadas ou subtraídas, ou de qualquer origem ilícita, tais como contrabando,
      falsificações ou adulterações; (iii) cigarro e derivados de tabaco e/ou que façam apologia ao
      hábito de fumar; (iv) conteúdos que promovam violência e maus tratos contra seres vivos de
      qualquer espécie; (v) conteúdos relacionados à prostituição ou similares, e quaisquer
      informações sobre produtos de material pornográfico, erótico, obsceno, inadequados para
      menores ou contrários a moral e aos bons costumes; (vi) conteúdos que promovam a violência
      e/ou discriminação, baseada em questões de raça, sexo, religião, nacionalidade, orientação
      sexual ou de qualquer outro tipo; (vii) conteúdos ou arquivos que contenham vírus, programas
      ou códigos que possam danificar e/ou roubar dados, informações ou o funcionamento de outros
      computadores, ou qualquer tentativa de explorar, rastrear ou testar vulnerabilidades do
      sistema do Perfil Profissional Online IClinic, e/ou burlar qualquer medida de segurança e/ou
      autenticação; (viii) conteúdos que falsifiquem, omitam ou simulem endereços de IP’s, de rede
      ou de correio eletrônico, na tentativa de ocultar a identidade e/ou autoria dos
      transgressores; (ix) atividades que violem as leis sobre a propriedade intelectual, tais como
      cópias não autorizadas, utilização de imagens ou textos sem autorização do autor, sejam elas
      marcas, réplicas e/ou falsificações; e (x) quaisquer outras atividades, produtos e/ou serviços
      vedados na legislação vigente, nos códigos de ética médica e de outros profissionais de saúde,
      e/ou que se caracterizem ilícitos penais ou contravenções.
    </Text>

    <Text kind="subdued" align="justify" margin="m-y-lg">
      6.1.1. Neste sentido, é responsabilidade exclusiva do USUÁRIO e do PACIENTE velar pela
      legalidade de suas atividades e informações cadastradas e a ICLINIC não assume nenhuma
      responsabilidade pela existência deles no Perfil Profissional Online IClinic, podendo excluir
      as informações de USUÁRIOS e PACIENTES que não cumpram com as restrições descritas no presente
      Termo, independente de aviso prévio. Portanto, o USUÁRIO e o PACIENTE serão os únicos e
      exclusivos responsáveis perante a ICLINIC ou terceiros, pela transgressão das regras que
      resulte em infração a direitos intelectuais de terceiros, estando a ICLINIC isenta de qualquer
      responsabilidade perante o titular destes direitos.
    </Text>

    <Text kind="subdued" align="justify" margin="m-y-lg">
      6.2. Os USUÁRIOS e os PACIENTES também não poderão, entre outras atitudes previstas no
      presente Termo: a) manipular os preços dos Planos e/ou informações da ICLINIC; b) agredir,
      caluniar, injuriar ou difamar a ICLINIC e/ou USUÁRIOS ou PACIENTES, por qualquer meio
      disponível para a comunicação dos USUÁRIOS e dos PACIENTES; c) usar qualquer robô, mecanismo
      de busca, aplicativo de pesquisa/recuperação de sites ou outro dispositivo para recuperar ou
      indexar qualquer parte dos serviços da ICLINIC ou coletar informações sobre os USUÁRIOS para
      qualquer finalidade não autorizada; d) enviar conteúdo com propaganda enganosa afirmando que
      tal conteúdo é patrocinado ou endossado pela ICLINIC; e) criar cadastros de USUÁRIOS por meios
      automatizados ou com intenções falsas ou fraudulentas; e f) transmitir quaisquer vírus, worms,
      defeitos, cavalos de Tróia ou quaisquer itens de natureza destrutiva. Estes tipos de
      comportamento poderão ser sancionados com a suspensão ou cancelamento do seu CADASTRO como
      USUÁRIO ou PACIENTE, sem prejuízo das ações legais que possam ocorrer pela configuração de
      delitos ou contravenções ou os prejuízos civis que possam causar aos demais USUÁRIOS.
    </Text>

    <Text kind="subdued" align="justify" margin="m-y-lg">
      6.3. Caso a ICLINIC verifique ou suspeite que algum USUÁRIO ou PACIENTE esteja violando alguma
      condição do presente Termo ou que esteja praticando atividade ilícita, além das medidas de
      proteção já descriminadas no presente Termo, tal como o cancelamento do referido CADASTRO, os
      dados do USUÁRIO/PACIENTE infrator serão encaminhados às autoridades correspondentes, exceto
      as informações cujo sigilo é previsto em lei, para que estas tomem as medidas judiciais
      cabíveis.
    </Text>

    <Text bold kind="ink" align="left" margin="m-y-lg">
      Cláusula 7ª – Da Propriedade Intelectual:
    </Text>
    <Text kind="subdued" align="justify" margin="m-y-lg">
      7.1. O uso comercial da expressão "ICLINIC" como marca, nome empresarial ou nome de domínio,
      bem como os conteúdos das telas relativas aos serviços disponíveis no Perfil Profissional
      Online ICLINIC, assim como os programas, conteúdos, bancos de dados, redes e arquivos, que
      permitem que o USUÁRIO acesse e use seu Perfil e que permitem que o PACIENTE visualize os
      perfis profissionais e agende consultas, são propriedade da ICLINIC e estão protegidos pelas
      leis e tratados internacionais de direito autoral, marcas, patentes, modelos e desenhos
      industriais. O uso indevido e a reprodução total ou parcial dos referidos conteúdos são
      proibidos, salvo quando autorizados expressamente pela ICLINIC.
    </Text>

    <Text kind="subdued" align="justify" margin="m-y-lg">
      7.2. A Plataforma ICLINIC poderá linkar o USUÁRIO e/ou PACIENTE a outros sites da internet, o
      que não significa que esses sites sejam de propriedade ou operados pela ICLINIC. Como não
      possui controle sobre esses sites, a ICLINIC não será responsável pelos conteúdos, práticas e
      serviços ofertados nos mesmos. A presença de links para outros sites não implica relação de
      sociedade, de supervisão, de cumplicidade ou solidariedade da ICLINIC para com esses sites e
      seus conteúdos.
    </Text>

    <Text kind="subdued" align="justify" margin="m-y-lg">
      7.3. Deverá ser expressamente autorizada pela ICLINIC a reprodução, exibição, distribuição
      e/ou alteração de quaisquer dos materiais, serviços e conteúdo do SITE e/ou do Perfil
      Profissional Online ICLINIC, uma vez que o mero uso do SITE e demais serviços oferecidos pela
      ICLINIC ao USUÁRIO não significa em nenhuma hipótese a cessão ou a transferência dos direitos
      sobre sua propriedade intelectual, bem como a de terceiros.
    </Text>

    <Text bold kind="ink" align="left" margin="m-y-lg">
      Cláusula 8ª – Das Disposições Gerais e da Arbitragem:
    </Text>
    <Text kind="subdued" align="justify" margin="m-y-lg">
      8.1. O USUÁRIO/PACIENTE expressamente aceita que a ICLINIC e/ou qualquer de seus parceiros
      enviem mensagens de e-mail de caráter informativo, referentes a comunicações específicas
      inerentes aos serviços prestados sob este Termo, bem como de natureza comercial, incluindo,
      entre outras, a ofertas dos parceiros da ICLINIC, novidades no Perfil Profissional Online
      ICLINIC, entre outras informações. Caso o USUÁRIO/PACIENTE não deseje mais receber referidas
      mensagens poderá solicitar o cancelamento pelo site www.agendarconsulta.com ou pelo link do
      próprio e-mail recebido.
    </Text>

    <Text kind="subdued" align="justify" margin="m-y-lg">
      8.2. A fim de manter e atender os últimos requisitos de mercado e de desenvolvimento
      tecnológicos, a ICLINIC poderá alterar, a seu critério, tanto em forma como em conteúdo,
      suspender ou cancelar, a seu exclusivo critério, a qualquer tempo, quaisquer dos serviços,
      produtos, utilidade ou aplicação, disponibilizados por si ou por terceiros, mediante prévio
      aviso ao USUÁRIO, inclusive com relação aos serviços prestados nos termos do presente Termo.
    </Text>

    <Text kind="subdued" align="justify" margin="m-y-lg">
      8.3. A tolerância da ICLINIC com o USUÁRIO, relativamente ao descumprimento de quaisquer das
      obrigações ora assumidas, não será considerada novação ou renúncia a qualquer direito,
      constituindo mera liberalidade, que não impedirá a ICLINIC de exigir posteriormente o fiel
      cumprimento das condições previstas neste Termo, a qualquer tempo.
    </Text>

    <Text kind="subdued" align="justify" margin="m-y-lg">
      8.5.1. A arbitragem será realizada de acordo com as regras da Arbitranet (
      <a href="www.arbitranet.com.br" rel="noopener noreferrer">
        www.arbitranet.com.br
      </a>
      ), vigentes na data na qual o pedido de arbitragem for apresentado, com aplicação subsidiária
      da Lei Federal 9.307, de 23 de setembro de 1996, e do Novo Código de Processo Civil Brasileiro
      (Lei nº 13.105, de 16 de março de 2015). Na hipótese de a Arbitranet estar inoperante, as
      partes poderão indicar, de comum acordo, outra câmara arbitral, observando-se o local da
      arbitragem previsto na Cláusula 11.5.2. O tribunal arbitral deverá ser constituído por 1 (um)
      árbitro, indicado conjuntamente pelas Partes. Caso não haja consenso das Partes na escolha do
      árbitro, o árbitro será indicado pelo presidente da Arbitranet.
    </Text>

    <Text kind="subdued" align="justify" margin="m-y-lg">
      8.5.2. O local da arbitragem será a cidade de Ribeirão Preto, Estado de São Paulo, Brasil, e o
      idioma da arbitragem será o Português, exceção feita a reclamações apresentadas por
      USUÁRIOS/PACIENTES que se enquadrem no conceito legal de consumidores, que poderão submeter ao
      foro de seu domicílio.
    </Text>

    <Text kind="subdued" align="justify" margin="m-y-lg">
      8.5.3. A decisão dos árbitros será final e vinculante, constituindo título executivo judicial,
      nos termos do Novo Código de Processo Civil (Lei nº 13.105, de 16 de março de 2015).
    </Text>

    <Text kind="subdued" align="justify" margin="m-y-lg">
      8.5.4. Na hipótese de não haver acordo entre as partes e o conflito ser resolvido em Sentença
      Arbitral, as custas e as despesas decorrentes do procedimento de arbitragem serão suportadas
      pela Parte vencida.
    </Text>

    <Text kind="subdued" align="justify" margin="m-y-lg">
      8.5.5. Da forma mais ampla permitida por lei, o procedimento arbitral e a sentença arbitral
      deverão ser mantidos em sigilo pelas Partes. Contudo, a violação desta garantia não afetará as
      previsões deste Termo acerca da arbitragem e da sentença arbitral.
    </Text>

    <Text kind="subdued" align="justify" margin="m-y-lg">
      8.5.6. Havendo a aceitação expressa de que trata a cláusula 8.5 acima para o estabelecimento
      da arbitragem, é garantido às Partes o direito de buscar assistência judicial: (i) para obter
      medidas incidentais protetivas de direitos, anteriormente à instituição da arbitragem, e
      qualquer ação nesse sentido não poderá ser interpretada como renúncia ao procedimento
      arbitral; ou (ii) para executar a decisão dos árbitros, incluindo a sentença arbitral.
    </Text>

    <Text kind="subdued" align="justify" margin="m-y-lg">
      8.5.7. Caso as Partes optem pela solução do conflito por via judicial ou busquem a assistência
      judicial para os fins das Cláusulas 8.5.6, elegem desde já como competente o juízo do Foro da
      Comarca de Ribeirão Preto, Estado de São Paulo, exceção feita a reclamações apresentadas por
      USUÁRIOS/PACIENTES que se enquadrem no conceito legal de consumidores, que poderão submeter ao
      foro de seu domicílio.
    </Text>

    <Text kind="subdued" align="justify" margin="m-y-lg">
      8.4. O USUÁRIO não poderá ceder o seu CADASTRO para terceiros ou outros USUÁRIOS. Por sua vez,
      a ICLINIC poderá, independentemente de qualquer aviso ou notificação, ceder os serviços do
      Perfil Profissional Online ICLINIC, no todo ou em parte, a empresas parceiras ou de seu grupo
      econômico, a qualquer momento.
    </Text>

    <Text kind="subdued" align="justify" margin="m-y-lg">
      8.5. Todos os itens deste Termo estão regidos pelas leis vigentes na República Federativa do
      Brasil. Para todos os assuntos referentes à sua interpretação e cumprimento e qualquer
      controvérsia decorrente ou relacionada a este Termo e aos serviços nele contemplada, mesmo
      após sua eventual rescisão, poderá ser solucionada por arbitragem, condicionada à ratificação
      dos termos abaixo em compromisso arbitral específico ou outra forma de aceitação expressa a
      pedido de arbitragem feito por uma das partes.
    </Text>

    <Text bold kind="ink" align="left" margin="m-y-lg">
      Cláusula 9ª – Das Declarações Finais:
    </Text>
    <Text kind="subdued" align="justify" margin="m-y-lg">
      9.1. O USUÁRIO/PACIENTE declara e concorda expressamente: a) ser maior de 18 anos e possuir
      capacidade jurídica, ou caso seja menor de 18 anos, ter expressa anuência de seus pais ou
      representantes legais; b) em instalar e manter atualizados programas Anti Spywares, Anti-vírus
      e outros que impeçam a violação do sistema que é utilizado para ter acesso ao SITE; c) não
      utilizar os serviços objeto do presente Termo para quaisquer transações ilícitas ou compra ou
      venda de produtos e/ou serviços ilícitos; d) em manter o sigilo da SENHA cadastrada, não a
      fornecendo a terceiros; e) em notificar imediatamente a ICLINIC caso tome ciência de qualquer
      violação de segurança relacionada ao serviço ou uso não autorizado de seu nome de USUÁRIO e
      SENHA; f) ter condições financeiras de arcar com os pagamentos, custos e despesas decorrentes
      do presente Termo; g) reconhecer que o presente Termo se formaliza, vinculando as Partes, com
      a sua aceitação eletrônica pelo USUÁRIO/PACIENTE, o que se fará mediante o clique no botão
      “Aceito o Termo de Uso”; h) que leu e está ciente e de pleno acordo com todos os termos e
      condições do presente Termo, razão pela qual o aceita de livre e espontânea vontade.
    </Text>

    <Text kind="subdued" align="justify" margin="m-y-lg">
      9.2. Assim, tendo em vista as cláusulas do presente Termo, o USUÁRIO/PACIENTE concorda
      integralmente com essas disposições, se comprometendo a respeitar as condições aqui previstas
      de forma irretratável e irrevogável, bem como a utilizar de modo proveitoso e legal os
      serviços a ele oferecidos.
    </Text>

    <Text kind="subdued" align="justify" margin="m-y-lg">
      9.3. Ao aceitar o presente Termo, o USUÁRIO/PACIENTE autoriza, desde já e expressamente, o uso
      gratuito de sua imagem, voz e nome em todas as peças e formas de divulgação de campanhas da
      ICLINIC, com ou sem indicação de seu nome, pelo prazo que durar cada uma dessas campanhas.
    </Text>

    <Text kind="subdued" align="justify" margin="m-y-lg">
      9.4. Considerando que a ICLINIC presta serviços na elaboração, desenvolvimento e licenciamento
      de software a mesma não possui qualquer responsabilidade em relação aos atendimentos,
      diagnósticos, tratamentos, entre outros serviços prestados pelo USUÁRIO que é um profissional
      da saúde.
    </Text>

    <Text bold kind="ink" align="left" margin="m-y-lg">
      Cláusula de Consentimento na Utilização de Dados
    </Text>
    <Text kind="subdued" align="justify" margin="m-y-lg">
      O USUÁRIO e/ou PACIENTE concorda que a ICLINIC poderá coletar e utilizar dados pessoais,
      pessoais sensíveis, técnicos e informação relacionada, incluindo, mas não se limitando à
      informação fornecida no momento do cadastro, mas podendo abranger informação técnica sobre o
      dispositivo, sistema e software de aplicativo, periféricos, entre outros, que poderão ser
      recolhidos periodicamente ou não, para facilitar a disponibilização de atualizações da
      plataforma ICLINIC, suporte dos serviços prestados ao USUÁRIO relacionados com a plataforma
      ICLINIC. A ICLINIC também poderá utilizar essas informações, desde que estejam em um formato
      que não identifique pessoalmente o USUÁRIO e/ou PACIENTE, para melhorar os seus produtos ou
      para fornecer serviços ou tecnologias ao USUÁRIO e/ou PACIENTE.
    </Text>
  </Flex>
);

export default Terms;
