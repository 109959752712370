// External
import React from 'react';

import generateClasses from 'app/shared/utils/generateClasses';
import style from './Badge.module.scss';

type BadgeProps = {
  color: 'blue' | 'green',
  icon?: React.ReactNode,
  text: string,
};

export default function Badge({
  color,
  icon,
  text,
}: BadgeProps): JSX.Element {
  return (
    <div
      className={generateClasses(style, 'badge', {
        color,
      })}
    >
      {icon && <div className={style['badge-icon']}>{icon}</div>}
      <p className={style['badge-text']}>{text}</p>
    </div>
  );
}
