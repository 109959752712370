import * as React from 'react';
import generateClasses from 'app/shared/utils/generateClasses';

import css from './Progress.module.scss';

/** Component */
const Progress: React.SFC<ProgressProps> = ({ percentage, color }) => (
  <div className={css.progress}>
    <div
      style={{ width: `${percentage}%` }}
      className={generateClasses(css, 'progress-bar', { color })}
    />
  </div>
);

/** Definitions */
type ProgressProps = {
  color?: string;
  percentage?: number;
};

Progress.defaultProps = {
  color: 'ink',
  percentage: 0,
};

export default Progress;
