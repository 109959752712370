import * as React from 'react';
import { connect } from 'react-redux';
import { Message, Flex, Text } from 'app/components';
import { notificationRemove } from 'app/state/notification/actions';

import css from './Notification.module.scss';
import autobind from 'autobind-decorator';

const MessageBody: React.SFC<MessageBodyProps> = ({ message }) => {
  if (message) {
    if (typeof(message) === 'string') {
      return <Text>{message}</Text>;
    } else {
      return (
        <>
          {message.map((message, index) => (
            <Text key={index} margin="m-bottom-xs">• {message}</Text>
          ))}
        </>
      );
    }
  }
  return null;
};

type MessageBodyProps = {
  message?: string | string[]
};

/** Component */
class Notification extends React.PureComponent<NotificationProps, {}> {

  static defaultProps = {
    timeout: 3000,
  };

  @autobind
  hideMessage(id) {
    setTimeout(() => {
      this.props.notificationRemove(id);
    }, this.props.timeout);
  }

  render(): React.ReactNode {
    const { messages } = this.props;
    return (
      <div className={css.notifications}>
        {messages.map((message) => {
          this.hideMessage(message.id);
          return (
            <Message key={`message-${message.id}`} kind={message.kind}>
              <Flex direction="col">
                <Text bold>{message.title}</Text>
                <MessageBody message={message.body} />
              </Flex>
            </Message>
          );
        })}
      </div>
    );
  }
}

/** Definitions */
type NotificationProps = {
  notificationRemove: (id: number | string) => void;
  timeout: number,
  messages: {
    kind: 'error' | 'success';
    body?: string | string[];
    title?: string;
    id: number | string;
  }[];
};

export default connect(
  state => state.notification,
  dispatch => ({ notificationRemove: id => dispatch(notificationRemove(id)) }),
)(Notification);
