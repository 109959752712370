// Internal
import { appTypes } from 'app/shared/utils/actions';

const notification = appTypes.defineAction('notification');

// eslint-disable-next-line import/prefer-default-export
export const NOTIFICATION = notification.defineAction('NOTIFICATION',
  [
    'ADD',
    'REMOVE',
  ],
);
