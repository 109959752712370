// External
import React from 'react';

import style from './CardProfile.module.scss';

type CardProfile = {
  cbo: string,
  location: string,
  time: string,
};

export default function CardProfile({
  cbo,
  location,
  time,
}: CardProfile): JSX.Element {
  return (
    <div className={style['card-profile']}>
      <div className={style.head}>
        <p className={style.small}>{time}</p>
        <img alt="Médico" src="static/images/doctor.svg" />
      </div>
      <div className={style.content}>
        <h4 className={style.title}>{cbo}</h4>
        <p className={style.description}>{location}</p>
      </div>
    </div>
  );
}
