import * as React from 'react';
import generateClasses from 'app/shared/utils/generateClasses';

import css from './Col.module.scss';

/** Component */
const Col: React.SFC<ColProps> = ({ children, padding, width, auto, margin }) => (
  <div
    className={generateClasses(css, 'column', { padding, margin })}
    style={{ width: !auto ? `${(width || 1) * 100}%` : 'auto' }}
  >
    {children}
  </div>
);

/** Definitions */
type ColProps = {
  children?: React.ReactNode;
  width?: number;
  auto?: boolean;
  margin?: string;
  padding?: string;
};

Col.defaultProps = {
  width: 1,
  auto: false,
  children: null,
};

export default Col;
