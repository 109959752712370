/* eslint-disable react/prop-types */
import * as React from 'react';
import generateClasses from 'app/shared/utils/generateClasses';

import css from './Text.module.scss';

/** Component */
const Text: React.SFC<TextProps> = ({
  kind,
  bold,
  wrap,
  align,
  margin,
  padding,
  children,
  underline,
  innerHTML,
  ...props
}) =>
  innerHTML ? (
    <div
      className={generateClasses(css, 'text', {
        kind,
        bold,
        wrap,
        align,
        margin,
        padding,
        underline,
      })}
      dangerouslySetInnerHTML={{ __html: innerHTML }}
      {...props}
    />
  ) : (
    <div
      className={generateClasses(css, 'text', {
        kind,
        bold,
        wrap,
        align,
        margin,
        padding,
        underline,
      })}
      {...props}
    >
      <p>{children}</p>
    </div>
  );

/** Definitions */
type TextProps = {
  wrap?: string;
  align?: string;
  bold?: boolean;
  margin?: string;
  padding?: string;
  underline?: boolean;
  children: React.ReactNode | null;
  kind?: 'subdued' | 'error' | 'bold';
  innerHTML?: string;
  itemScope?: boolean;
  itemProp?: string;
  itemType?: string;
};

Text.defaultProps = {
  wrap: '',
  align: '',
  margin: '',
  padding: '',
  kind: 'subdued',
  bold: false,
  underline: false,
  innerHTML: '',
  itemScope: false,
  itemProp: '',
  itemType: '',
};

export default Text;
