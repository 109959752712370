import * as React from 'react';
import uuid from 'uuid/v4';

import css from './Stepper.module.scss';
import { StepFragment, StepperInterface } from './typings';

/** Component */
const Stepper: StepperInterface<StepperProps> = ({ children, active }) => {
  const allSteps = React.Children.toArray(children) as StepFragment[];
  const numberOfSteps = allSteps.length - 1;
  const activeStep = active - 1 || 0;
  const steps = allSteps.map((step, index) => ({
    id: uuid(),
    label: step.props.children,
    isDone: activeStep >= index,
  }));

  return (
    <div className={css.stepper} style={{ width: `calc(100% - ${(numberOfSteps + 1) * 7}px)` }}>
      <div className={css['stepper-steps']}>
        {steps.map(step => (
          <div
            className={`${css['stepper-step']} ${step.isDone ? css['stepper-step-active'] : null}`}
            data-label={step.label}
            key={step.id}
          />
        ))}
      </div>
      <div className={css['stepper-line']}>
        <div
          className={css['stepper-progress']}
          style={{ width: `${Math.ceil((100 * activeStep) / numberOfSteps)}%` }}
        />
      </div>
    </div>
  );
};

/** Fragments */
Stepper.Step = () => null;

/** Definitions */
type StepperProps = {
  active: number;
  children: React.ReactNode;
};

export default Stepper;
