import * as React from 'react';
import generateClasses from 'app/shared/utils/generateClasses';

import css from './Caption.module.scss';

/** Component */
const Caption: React.SFC<CaptionProps> = ({ children, kind, align, padding, bold, margin }) => (
  <div
    className={generateClasses(css, 'caption', {
      kind,
      bold,
      align,
      margin,
      padding,
    })}
  >
    {children}
  </div>
);

/** Definitions */
type CaptionProps = {
  kind?: string;
  bold?: boolean;
  align?: string;
  margin?: string;
  padding?: string;
  children?: React.ReactNode;
};

Caption.defaultProps = {
  bold: false,
  children: null,
};

export default Caption;
