import * as React from 'react';
import { Flex, Text, H3, Divisor } from 'app/components';

/** Component */
const AddressItem: React.SFC<AddressItemProps> = ({ index, address }) => (
  <Flex direction="col">
    {index > 0 && <Divisor />}
    <H3 size="body">
      {index + 1}. {address.name}
    </H3>
    <Text kind="subdued">
      {address.address}, {address.number}
    </Text>
    <Text kind="subdued">{address.complement}</Text>
    <Text kind="subdued">
      {address.zipcode} - {address.neighborhood}
    </Text>
    <Text kind="subdued">
      {address.city} - {address.state}
    </Text>
  </Flex>
);

/** Definitions */
type AddressItemProps = {
  index: number;
  address: {
    name?: string;
    city?: string;
    state?: string;
    number?: string;
    address?: string;
    zipcode?: string;
    complement?: string;
    id?: string | number;
    neighborhood?: string;
  };
};

export default AddressItem;
