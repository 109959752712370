import * as React from 'react';
import uuid from 'uuid/v4';
import autobind from 'autobind-decorator';
import { Button, Flex } from 'app/components';
import Form from 'app/shared/components/FormValidate';

/** Component */
class MultiForm extends React.PureComponent<MultiFormProps, MultiFormState> {
  static defaultProps = {
    validate: () => {},
    hideLabel: 'Cancelar',
    actionLabel: 'Adicionar novo',
    header: null,
  };

  state = {
    data: {},
    values: {},
    is_visible: false,
  };

  @autobind
  onPost(data: {}): void {
    this.props.onSubmit(data);
    this.setState({ data: {}, is_visible: false });
  }

  @autobind
  addForm(): void {
    this.setState({ is_visible: true });
  }

  @autobind
  removeForm(): void {
    this.setState({ is_visible: false });
  }

  render(): React.ReactNode {
    return (
      <div>
         {(this.state.is_visible || this.props.values.length > 0) && this.props.header}
        <div>
          {this.props.values.map(item => (
            <Form
              values={item}
              validate={this.props.validate}
              onSubmitSuccess={this.props.onSubmit}
              key={`form-item-${uuid()}-${item.id}`}
            >
              {this.props.children}
            </Form>
          ))}
        </div>
        <div>
          {this.state.is_visible && (
            <Form
              onSubmitSuccess={this.onPost}
              validate={this.props.validate}
              values={this.props.initialState}
            >
              {form => this.props.children(form, this.removeForm)}
            </Form>
          )}
        </div>
        <Flex margin="m-top-lg">
          <Button kind="link" onClick={this.addForm} block disabled={this.state.is_visible}>
            {this.props.actionLabel}
          </Button>
        </Flex>
      </div>
    );
  }
}

/** Definitions */
type MultiFormProps = {
  values: { id: number | string }[];
  initialState?: {};
  hideLabel?: string;
  actionLabel?: string;
  onSubmit: (e: {}) => any;
  validate?: (values: {}) => any;
  children: (form: {}, removeForm: () => void) => React.ReactNode;
  header?: React.ReactNode | null;
};

type MultiFormState = {
  data: {};
  values: {};
  is_visible: boolean;
};

export default MultiForm;
