import React from 'react';
import uuid from 'uuid/v4';
import { Label } from 'app/components';
import generateClasses from 'app/shared/utils/generateClasses';

import css from './RadioInput.module.scss';

/** Component */
const RadioInput: React.SFC<RadioInputProps> = ({
  kind,
  name,
  label,
  value,
  checked,
  onChange,
}) => {
  const id = uuid();
  return (
    <div className={generateClasses(css, 'radio-input', { kind })}>
      {label && (
        <div className={css['radio-input-label']}>
          <Label kind={kind} for={id}>
            <div className={css['radio-input-wrapper']}>
              <input
                id={id}
                name={name}
                type="radio"
                value={value}
                checked={checked}
                onChange={onChange}
                className={css['radio-input-hide']}
              />
              <div className={css['radio-input-element']} tabIndex={0} role="button" />
              {label}
            </div>
          </Label>
        </div>
      )}
    </div>
  );
};

/** Definitions */
type RadioInputProps = {
  kind?: string;
  name?: string;
  checked?: boolean;
  label?: React.ReactNode;
  value?: string | number | string[];
  onChange?: (e: React.ChangeEvent) => void;
};

RadioInput.defaultProps = {
  checked: false,
  onChange: () => {},
};

export default RadioInput;
