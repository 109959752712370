import * as React from 'react';
import generateClasses from 'app/shared/utils/generateClasses';

import css from './H3.module.scss';

/** Component */
const H3: React.SFC<H3Props> = ({ children, kind, size, align, padding, margin }) => (
  <h3 className={generateClasses(css, 'h3', { kind, size, align, padding, margin })}>{children}</h3>
);

/** Definitions */
type H3Props = {
  kind?: string;
  size?: 'xl' | 'lg' | 'md' | 'sm' | 'heading' | 'body';
  align?: string;
  margin?: string;
  padding?: string;
  children?: React.ReactNode;
};

export default H3;
