import * as React from 'react';

import css from './Divisor.module.scss';

/** Component */
const Divisor: React.SFC = () => (
  <div className={css.divisor} />
);

export default Divisor;
