module.exports = {
  // Declare path to static image files
  logo: '/static/images/logo.png',
  avatar: '/static/images/avatar.svg',
  profile_example: '/static/images/profile-example.jpg',
  clouds: '/static/images/nuvens.png',
  '404_line': '/static/images/linha-404.gif',
  video: '/static/images/video.svg',
  sms: '/static/images/sms.svg',
  sucess: '/static/images/sucesso.svg',
};
