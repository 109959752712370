import * as React from 'react';

import css from './Container.module.scss';

/** Component */
const Container: React.SFC <ContainerInterface> = ({ children, relative }) => (
  <div className={`${css.container} ${relative ? css['container-relative'] : ''}`}>{children}</div>
);

/** Definitions */
interface ContainerInterface {
  children: React.ReactNode;
  relative: boolean
};

Container.defaultProps = {
  relative: false
};

export default Container;
