import * as React from 'react';
import { Button } from 'app/components';

/** Component */
const DeleteImage: React.SFC<DeleteImageProps> = ({ onDelete }) => (
  <Button onClick={onDelete}>Deletar imagem</Button>
);

/** Definitions */
type DeleteImageProps = {
  onDelete: (e: React.MouseEvent) => void;
};

export default DeleteImage;
