import * as React from 'react';
import autobind from 'autobind-decorator';
import generateClasses from 'app/shared/utils/generateClasses';
import DateFormat, { removeSeconds } from 'app/shared/utils/dateFormat';
import { Col, Flex, Icon, Text, Button, Caption, IconButton, Loading } from 'app/components';
import css from './TimeCalendar.module.scss';

type Day = {
  date: string;
  end_time?: string;
  duration?: number;
  start_time?: string;
}

const groupByDate = (days: Day[]): {} =>
  days.reduce((acc, v) => {
    acc[v.date] = acc[v.date] || [];
    acc[v.date].push(v);
    return acc;
  }, Object.create(null));

const onClickToggleMore = (e: React.MouseEvent, parentEl: HTMLElement) => {
  (e.target as HTMLElement).style.display = 'none';
  const parentNodeColumn = parentEl;
  if (parentNodeColumn.classList.contains(css['hide-extra-days'])) {
    parentNodeColumn.classList.remove(css['hide-extra-days']);
  } else {
    parentNodeColumn.classList.add(css['hide-extra-days']);
  }
};

class TimeCalendar extends React.PureComponent<TimeCalendarProps, {}> {
  static defaultProps = {
    days: [],
    columns: 4,
    eventsLimit: 4,
    isFetching: false,
    disabledNext: false,
    startDate: new Date(),
    disabledPrevious: false,
  };

  @autobind
  createWeekDaysNavigation(startDate: Date): string[] {
    // create an array to fill dates in a period, based on current day + 1
    const arr = Array(this.props.columns).fill(0);
    const nextDate = new DateFormat(startDate);
    return arr.map((): string => {
      const r = nextDate.toString();
      nextDate.addDays(1);
      return r;
    });
  };

  @autobind
  groupByWeek(startDate: Date, days: Day[]) {
    const eventsGrouped = groupByDate(days);
    const weekDaysNavigation = this.createWeekDaysNavigation(startDate);
    return weekDaysNavigation.map(weekDay => [weekDay, eventsGrouped[weekDay] || []]);
  };

  render(): React.ReactNode {
    const {
      days,
      startDate,
      isFetching,
      onClickNext,
      onClickNextAvailable,
      eventsLimit,
      onClickEvent,
      disabledNext,
      onClickPrevious,
      disabledPrevious,
    } = this.props;
    const eventsGrouped = days && days.length >= 0 ? this.groupByWeek(startDate, days) : [];

    return (
      <div className={generateClasses(css, 'time-calendar', { isFetching })}>
        <div>
          <IconButton onClick={onClickPrevious} disabled={disabledPrevious}>
            <Icon name="arrowLeft" />
          </IconButton>
        </div>
        <div className={css['time-calendar-days']}>
          {isFetching ? (
            <Flex items="items-start">
              <div className={css['time-calendar-loading']}>
                <Loading />
              </div>
            </Flex>
          ) : (
            <Flex items="items-start">
              {eventsGrouped.map((group) => {
                let parentEl;
                const currentDayString = group[0];
                const currentDay = new DateFormat(currentDayString);
                const events = group[1];
                return (
                  <div key={currentDayString} ref={el => { parentEl = el; }} className={css['hide-extra-days']}>
                    <Flex direction="col" margin="m-x" justify="justify-center">
                      <Caption align="center" kind="subdued">
                        {currentDay.getDayWeekName(true)}
                      </Caption>
                      <Text
                        align="center"
                        margin="m-bottom-sm"
                      >{`${currentDay.getDay()}/${currentDay.getMonth()}/${currentDay.getYear()}`}</Text>
                      {events.map((event, index) => (
                        <div key={index} className={index >= eventsLimit ? css.extra : ''}>
                          <Col margin="m-y-xs">
                            <Button data-id="button-event" block onClick={() => onClickEvent(event)} kind="info">
                              {removeSeconds(event.start_time)}
                            </Button>
                          </Col>
                        </div>
                      ))}
                      {events.length === 0 && (
                        <Flex margin="m-y-xs" items="items-center" justify="justify-center">
                          {days.length === 0 ? '' : '--'}
                        </Flex>
                      )}
                      {events.length > eventsLimit && (
                        <Col margin="m-y-xs">
                          <Button dataId="button-more" kind="default" onClick={(e) => onClickToggleMore(e, parentEl)}>
                            Mais..
                          </Button>
                        </Col>
                      )}
                    </Flex>
                  </div>
                );
              })}
            </Flex>
          )}
          {days.length === 0 && !isFetching && (
            <div className={css['time-calendar-next']}>
              <Flex justify="justify-center" items="items-center">
                <Button dataId="button-next-available" kind="info" onClick={onClickNextAvailable}>Ver próximo horário disponível</Button>
              </Flex>
            </div>
          )}
        </div>
        <div>
          <IconButton onClick={onClickNext} disabled={disabledNext}>
            <Icon name="arrowRight" />
          </IconButton>
        </div>
      </div>
    );
  }
}

/** Definitions */
type TimeCalendarProps = {
  startDate: Date;
  eventsLimit: number;
  days: Day[];
  columns?: number;
  isFetching?: boolean;
  disabledNext?: boolean;
  disabledPrevious?: boolean;
  onClickNext: (e: {}) => void;
  onClickNextAvailable: (e: {}) => void;
  onClickEvent: (e: {}) => void;
  onClickPrevious: (e: {}) => void;
};

export default TimeCalendar;
