import * as React from "react";

/**
 * Component that alerts if you click outside of it
 */
class ClickOutside extends React.Component<ClickOutsideProps, {}> {
  wrapperRef: HTMLElement;

  constructor(props) {
    super(props);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }
  /**
   * Set the wrapper ref
   */
  setWrapperRef(node) {
    this.wrapperRef = node;
  }
  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.props.onClickOutside(event);
    }
  }
  render() {
    return <div ref={this.setWrapperRef}>{this.props.children}</div>;
  }
}

type ClickOutsideProps = {
  onClickOutside: (...args: any[]) => any
};

export default ClickOutside;
