import * as React from 'react';
import generateClasses from 'app/shared/utils/generateClasses';

import css from './Loading.module.scss';

/** Component */
const Loading: React.SFC<LoadingProps> = ({ main }) => (
  <div className={generateClasses(css, 'loading', { main })}>
    <div />
    <div />
    <div />
    <div />
  </div>
);

/** Definitions */
type LoadingProps = {
  main?: boolean;
};

export default Loading;
