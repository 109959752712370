import { ReactElement, ReactNode, MouseEvent, PureComponent } from 'react';

export class DropdownInterface<P = {}, S = {}, SS = any> extends PureComponent<P, S, SS> {
  static Menu?: any;
  static Trigger?: any;
}

export interface DropdownFragmentsInterface {
  Menu?: ReactElement<any>;
  Trigger?: ReactElement<TriggerTypes>;
}

export type TriggerTypes = {
  children: ReactNode;
  onToggle: () => void;
  onClick: (e: MouseEvent) => void;
};

export type MenuTypes = {
  children: ReactNode;
  onClose: () => void;
};
