import { Children } from 'react';

const extractedFragments = (children) =>
  Children.toArray(children).reduce(
    (acc, child) => ({
      ...acc,
      [child.type.displayName ? child.type.displayName : 'Main']: child,
    }),
    {},
  );

export default extractedFragments;
