import * as React from 'react';
import generateClasses from 'app/shared/utils/generateClasses';

import css from './H1.module.scss';

/** Component */
const H1: React.SFC<H1Props> = ({ children, kind, size, align, padding, margin }) => (
  <h1 className={`${generateClasses(css, 'h1', { kind, size, align, padding, margin })}`}>
    {children}
  </h1>
);

/** Definitions */
type H1Props = {
  kind?: string;
  size?: 'xl' | 'lg' | 'md' | 'sm' | 'heading' | 'body';
  align?: string;
  margin?: string;
  padding?: string;
  children?: React.ReactNode;
};

export default H1;
