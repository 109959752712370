import * as React from 'react';
import { Button } from 'app/components';

import css from './UploadImage.module.scss';

/** Component */
const UploadImage: React.SFC<UploadImageProps> = ({ onLoad }) => (
  <div className={css['image-upload']}>
    <input
      type="file"
      accept="image/*"
      name="image-upload"
      ref={el => (input = el)}
      className={css['image-upload-input']}
      onChange={e => parseImage(e, onLoad)}
    />
    <Button onClick={triggerInput}>Enviar imagem</Button>
  </div>
);

/** Constants */
let input: HTMLInputElement | null;

/** Functions */
const parseImage = (
  e: React.ChangeEvent,
  onLoad: (obj: { image: { file: {}; src: string | ArrayBuffer | null }; form: FormData }) => void,
): void => {
  const target = e.target as HTMLInputElement;
  if (!target || !target.files || !target.files.length) return;
  const file = target.files[0];
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    const image = {
      file,
      src: reader.result,
    };
    const form = new FormData();
    form.append('picture', file);
    onLoad({ form, image });
  };
};

const triggerInput = (): void => {
  if (input) {
    input.value = '';
    input.click();
  }
};

/** Definitions */
type UploadImageProps = {
  onLoad: (obj: { image: { file: {}; src: string | ArrayBuffer | null }; form: FormData }) => void;
};

export default UploadImage;
