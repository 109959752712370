import * as React from 'react';
import uuid from 'uuid/v4';
import { Label, Caption } from 'app/components';
import generateClasses from 'app/shared/utils/generateClasses';

import css from './CheckboxField.module.scss';

/** Component */
const CheckboxField: React.SFC<CheckboxFieldProps> = ({
  kind,
  name,
  label,
  value,
  message,
  onClick,
  onChange,
  isValid,
}) => {
  const id = uuid();
  return (
    <div className={generateClasses(css, 'checkbox-field', { kind })}>
      {label && (
        <div className={css['checkbox-field-label-container']}>
            <div className={css['checkbox-field-wrapper']}>
              <input
                type="checkbox"
                checked={value}
                id={id}
                name={name}
                onChange={() => {
                  if (onClick) onClick(
                    {
                      preventDefault: () => {},
                      target: { value: !value, name },
                    },
                    onChange ? onChange : () => {},
                  )
                }}
              />{' '}
              <p className={!isValid ? css['checkbox-field-label'] : css['checkbox-field-error']}>{label}</p>
            </div>
        </div>
      )}
      {message && (
        <Caption padding="p-xs" kind={kind || 'subdued'}>
          {message}
        </Caption>
      )}
    </div>
  );
};

/** Definitions */
type CheckboxFieldProps = {
  name?: string;
  kind?: string;
  label?: string;
  value?: boolean;
  isValid?: boolean;
  message?: string;
  onChange?: () => void;
  children?: React.ReactNode;
  onClick?: (e: {}, callback: () => void) => void;
};

CheckboxField.defaultProps = {
  value: false,
  onClick: () => {},
  onChange: () => {},
};

export default CheckboxField;
