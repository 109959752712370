// External
import React from 'react';

// Internal
import { Container, Text, Icon } from 'app/components';
import css from './Footer.module.scss';

type FooterProps = {
  children?: React.ReactNode
};

export default function Footer({
  children,
}: FooterProps): JSX.Element {
  const fullYear = new Date().getFullYear();
  return (
    <div className={css.footer}>
      <Container>
        <div className={css['footer-content']}>
          <div className={css['footer-copywrite']}>
            <Text kind="subdued">
              <span className={css['footer-label']}>©</span>
              <span>{fullYear}</span>
              <span> - Feito com </span>
              <span className={css['footer-icon']}>
                <Icon name="heart" />
              </span>
              <span> por iClinic</span>
            </Text>
          </div>
          <div className={css['footer-body']}>{children}</div>
        </div>
      </Container>
    </div>
  );
}
