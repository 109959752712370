import * as React from 'react';
import { Card, Icon, IconButton } from 'app/components';
import generateClasses from 'app/shared/utils/generateClasses';
import extractFragments from 'app/shared/utils/extractFragments';

import css from './Modal.module.scss';
import { ModalInterface, ModalFragmentsTypes } from './typings';

/** Component */
const Modal: ModalInterface<ModalProps> = ({
  size,
  onClose,
  children,
  isVisible,
  responsive,
  showCloseButton,
}) => {
  const { Title, Body, Footer }: ModalFragmentsTypes = extractFragments(children);

  if (process.browser) {
    // client-side-only code
    const bodyElement = window.document.body;
    bodyElement.style.overflow = isVisible && responsive ? 'hidden' : '';
  }

  return isVisible ? (
    <div className={generateClasses(css, 'modal', { size, responsive })}>
      <div className={css['modal-content']}>
        <Card>
          {Title && (
            <Card.Title>
              {Title}
              {showCloseButton && (
                <div className={css['modal-clear']}>
                  <IconButton onClick={onClose}>
                    <Icon name="clear" />
                  </IconButton>
                </div>
              )}
            </Card.Title>
          )}
          {Body && <Card.Body>{Body}</Card.Body>}
          {Footer && <Card.Footer>{Footer}</Card.Footer>}
        </Card>
      </div>
      <div role="button" tabIndex={1} className={css['modal-overlay']} onClick={onClose} />
    </div>
  ) : null;
};

/** Fragments */
Modal.Title = function Title({ children }) {
  return children;
};
Modal.Title.displayName = 'Title';

Modal.Body = function Body({ children }) {
  return children;
};
Modal.Body.displayName = 'Body';

Modal.Footer = function Footer({ children }) {
  return children;
};
Modal.Footer.displayName = 'Footer';

/** Definitions */
type ModalProps = {
  size?: 'lg';
  isVisible: boolean;
  onClose?: () => void;
  showCloseButton?: boolean;
  responsive?: boolean;
  children?: React.ReactNode;
};

Modal.defaultProps = {
  children: null,
  onClose: () => {},
  showCloseButton: false,
  responsive: false,
};

export default Modal;
