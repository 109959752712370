
const generateClasses = (stylesheet, base, props) => {
  const classes = [stylesheet[base]];
  Object.keys(props).forEach((name) => {
    const value = (typeof props[name] === 'boolean' && props[name]) ? name : props[name];
    if (value) classes.push(stylesheet[`${base}-${value}`]);
  });

  return classes.join(' ');
};

export default generateClasses;
