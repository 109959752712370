import * as React from 'react';
import generateClasses from 'app/shared/utils/generateClasses';

import css from './IconButton.module.scss';

/** Component */
const IconButton: React.SFC<IconButtonProps> = ({ children, src, onClick, disabled }) => (
  <button
    className={generateClasses(css, 'icon-button', { disabled })}
    disabled={disabled}
    onClick={onClick}
  >
    {src || children}
  </button>
);

/** Definitions */
type IconButtonProps = {
  disabled?: boolean;
  src?: React.ReactNode;
  children?: React.ReactNode;
  onClick?: (e: React.MouseEvent) => void;
};

IconButton.defaultProps = {
  children: null,
  disabled: false,
  onClick: () => {},
};

export default IconButton;
