import * as React from 'react';
import generateClasses from 'app/shared/utils/generateClasses';

import css from './H4.module.scss';

/** Component */
const H4: React.SFC<H4Props> = ({ children, kind, align, padding, margin }) => (
  <h4 className={generateClasses(css, 'h4', { kind, align, padding, margin })}>{children}</h4>
);

/** Definitions */
type H4Props = {
  kind?: string;
  align?: string;
  padding?: string;
  margin?: string;
  children?: React.ReactNode;
};

export default H4;
