// External
import * as React from 'react';
import generateClasses from 'app/shared/utils/generateClasses';

// Internal
import css from './Button.module.scss';

type ButtonProps = {
  size?: string;
  block?: boolean;
  dataId?: string;
  disabled?: boolean;
  children?: React.ReactNode;
  onClick?: (...args: any[]) => any;
  type?: 'submit' | 'button' | 'reset',
  kind?: 'success' | 'default' | 'info' | 'warning' | 'link';
};

const Button: React.FunctionComponent<ButtonProps> = ({
  kind = 'default',
  block = false,
  children = null,
  onClick = () => {},
  disabled = false,
  type = 'button',
  size,
  dataId,
}: ButtonProps) => (
  // eslint-disable-next-line react/button-has-type
  <button
    data-id={dataId}
    className={generateClasses(css, 'button', {
      kind, block, size, disabled,
    })}
    disabled={disabled}
    onClick={onClick}
    type={type}
  >
    {children}
  </button>
);

export default Button;
