import React from 'react';

export default {
  /**
   * Heart icon
   */
  heart: (
    <g>
      <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z" />
      <path d="M0 0h24v24H0z" fill="none" />
    </g>
  ),

  /**
   * Clear icon
   */
  clear: (
    <g>
      <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
      <path d="M0 0h24v24H0z" fill="none" />
    </g>
  ),
  /**
   * Arrow right icon
   */
  arrowRight: (
    <g>
      <path d="M8.59,16.59L13.17,12L8.59,7.41L10,6l6,6l-6,6L8.59,16.59z" />
      <path fill="none" d="M0,0h24v24H0V0z" />
    </g>
  ),
  /**
   * Arrow left icon
   */
  arrowLeft: (
    <g>
      <path d="M15.41,16.59L10.83,12l4.58-4.59L14,6l-6,6l6,6L15.41,16.59z" />
      <path fill="none" d="M0,0h24v24H0V0z" />
    </g>
  ),
  /**
   * Clock icon
   */
  clock: (
    <g>
      <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" />
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z" />
    </g>
  ),

  /**
   * Whatsapp icon
   */
  whatsapp: (
    <g>
      <path
        style={{ fill: '#2CB742' }}
        d="M0,58l4.988-14.963C2.457,38.78,1,33.812,1,28.5C1,12.76,13.76,0,29.5,0S58,12.76,58,28.5 S45.24,57,29.5,57c-4.789,0-9.299-1.187-13.26-3.273L0,58z"
      />
      <path
        style={{ fill: '#ffffff' }}
        d="M47.683,37.985c-1.316-2.487-6.169-5.331-6.169-5.331c-1.098-0.626-2.423-0.696-3.049,0.42 c0,0-1.577,1.891-1.978,2.163c-1.832,1.241-3.529,1.193-5.242-0.52l-3.981-3.981l-3.981-3.981c-1.713-1.713-1.761-3.41-0.52-5.242 c0.272-0.401,2.163-1.978,2.163-1.978c1.116-0.627,1.046-1.951,0.42-3.049c0,0-2.844-4.853-5.331-6.169 c-1.058-0.56-2.357-0.364-3.203,0.482l-1.758,1.758c-5.577,5.577-2.831,11.873,2.746,17.45l5.097,5.097l5.097,5.097 c5.577,5.577,11.873,8.323,17.45,2.746l1.758-1.758C48.048,40.341,48.243,39.042,47.683,37.985z"
      />
    </g>
  ),

  /**
   * Phone icon
   */
  phone: (
    <g>
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M17 1.01L7 1c-1.1 0-1.99.9-1.99 2v18c0 1.1.89 2 1.99 2h10c1.1 0 2-.9 2-2V3c0-1.1-.9-1.99-2-1.99zM17 19H7V5h10v14z" />
    </g>
  ),

  /**
   * Error icon
   */
  error: (
    <g>
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8 0-1.85.63-3.55 1.69-4.9L16.9 18.31C15.55 19.37 13.85 20 12 20zm6.31-3.1L7.1 5.69C8.45 4.63 10.15 4 12 4c4.42 0 8 3.58 8 8 0 1.85-.63 3.55-1.69 4.9z" />
    </g>
  ),

  /**
   * Success icon
   */
  success: (
    <g>
      <path fill="none" d="M0 0h24v24H0V0zm0 0h24v24H0V0z" />
      <path d="M16.59 7.58L10 14.17l-3.59-3.58L5 12l5 5 8-8zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" />
    </g>
  ),

  /**
   * E-mail icon
   */
  email: (
    <g>
      <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z" />
      <path d="M0 0h24v24H0z" fill="none" />
    </g>
  ),

  /**
   * Globe icon
   */
  globe: (
    <g>
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 17.93c-3.95-.49-7-3.85-7-7.93 0-.62.08-1.21.21-1.79L9 15v1c0 1.1.9 2 2 2v1.93zm6.9-2.54c-.26-.81-1-1.39-1.9-1.39h-1v-3c0-.55-.45-1-1-1H8v-2h2c.55 0 1-.45 1-1V7h2c1.1 0 2-.9 2-2v-.41c2.93 1.19 5 4.06 5 7.41 0 2.08-.8 3.97-2.1 5.39z" />
    </g>
  ),

  /**
   * Instagram icon
   */
  instagram: (
    <g>
      <path d="M256,49.471c67.266,0,75.233.257,101.8,1.469,24.562,1.121,37.9,5.224,46.778,8.674a78.052,78.052,0,0,1,28.966,18.845,78.052,78.052,0,0,1,18.845,28.966c3.45,8.877,7.554,22.216,8.674,46.778,1.212,26.565,1.469,34.532,1.469,101.8s-0.257,75.233-1.469,101.8c-1.121,24.562-5.225,37.9-8.674,46.778a83.427,83.427,0,0,1-47.811,47.811c-8.877,3.45-22.216,7.554-46.778,8.674-26.56,1.212-34.527,1.469-101.8,1.469s-75.237-.257-101.8-1.469c-24.562-1.121-37.9-5.225-46.778-8.674a78.051,78.051,0,0,1-28.966-18.845,78.053,78.053,0,0,1-18.845-28.966c-3.45-8.877-7.554-22.216-8.674-46.778-1.212-26.564-1.469-34.532-1.469-101.8s0.257-75.233,1.469-101.8c1.121-24.562,5.224-37.9,8.674-46.778A78.052,78.052,0,0,1,78.458,78.458a78.053,78.053,0,0,1,28.966-18.845c8.877-3.45,22.216-7.554,46.778-8.674,26.565-1.212,34.532-1.469,101.8-1.469m0-45.391c-68.418,0-77,.29-103.866,1.516-26.815,1.224-45.127,5.482-61.151,11.71a123.488,123.488,0,0,0-44.62,29.057A123.488,123.488,0,0,0,17.3,90.982C11.077,107.007,6.819,125.319,5.6,152.134,4.369,179,4.079,187.582,4.079,256S4.369,333,5.6,359.866c1.224,26.815,5.482,45.127,11.71,61.151a123.489,123.489,0,0,0,29.057,44.62,123.486,123.486,0,0,0,44.62,29.057c16.025,6.228,34.337,10.486,61.151,11.71,26.87,1.226,35.449,1.516,103.866,1.516s77-.29,103.866-1.516c26.815-1.224,45.127-5.482,61.151-11.71a128.817,128.817,0,0,0,73.677-73.677c6.228-16.025,10.486-34.337,11.71-61.151,1.226-26.87,1.516-35.449,1.516-103.866s-0.29-77-1.516-103.866c-1.224-26.815-5.482-45.127-11.71-61.151a123.486,123.486,0,0,0-29.057-44.62A123.487,123.487,0,0,0,421.018,17.3C404.993,11.077,386.681,6.819,359.866,5.6,333,4.369,324.418,4.079,256,4.079h0Z" />
      <path d="M256,126.635A129.365,129.365,0,1,0,385.365,256,129.365,129.365,0,0,0,256,126.635Zm0,213.338A83.973,83.973,0,1,1,339.974,256,83.974,83.974,0,0,1,256,339.973Z" />
      <circle cx="390.476" cy="121.524" r="30.23" />
    </g>
  ),

  /**
   * Facebook icon
   */
  facebook: (
    <g>
      <g transform="matrix(1 0 0 -1 0 1536)">
        <path d="M754.4,1518.9v-155.5H662c-33.8,0-56.5-7.1-68.3-21.2c-11.8-14.1-17.7-35.3-17.7-63.6v-111.3h172.6l-23-174.3H576V546H395.8v447H245.6v174.3h150.2v128.4c0,73,20.4,129.7,61.3,169.9c40.8,40.2,95.2,60.4,163.1,60.4C677.9,1526,722.6,1523.6,754.4,1518.9z" />
      </g>
    </g>
  ),

  /**
   * Delete icon
   */
  delete: (
    <g>
      <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" />
      <path d="M0 0h24v24H0z" fill="none" />
    </g>
  ),

  /**
   * Success illustration
   */
  complete: (
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-688.000000, -386.000000)">
        <g transform="translate(688.000000, 386.000000)">
          <g transform="translate(0.000000, 0.015543)">
            <path
              d="M55.4666667,64 L8.53333333,64 C3.84,64 0,60.1178136 0,55.3729191 L0,8.62708095 C0,3.8814675 3.84,0 8.53333333,0 L55.4666667,0 C60.16,0 64,3.8814675 64,8.62708095 L64,55.3729191 C64,60.1178136 60.16,64 55.4666667,64"
              id="Fill-1"
              fill="#E8F2F8"
            />
            <g transform="translate(13.000000, 12.000000)">
              <path
                d="M36.8404364,19.8077091 C36.8404364,29.3604364 29.0971636,37.1037091 19.5444364,37.1037091 C9.99170909,37.1037091 2.24843636,29.3604364 2.24843636,19.8077091 C2.24843636,10.2549818 9.99170909,2.51170909 19.5444364,2.51170909 C29.0971636,2.51170909 36.8404364,10.2549818 36.8404364,19.8077091"
                id="Fill-1"
                fill="#FFFFFF"
              />
              <path
                d="M18.5013091,21.7799273 L15.6169455,18.8955636 C14.9893091,18.2679273 13.9689455,18.2679273 13.3420364,18.8955636 C12.7136727,19.5239273 12.7136727,20.5421091 13.3420364,21.1704727 L17.3638545,25.1922909 C17.6780364,25.5072 18.0896727,25.6642909 18.5013091,25.6642909 C18.9129455,25.6642909 19.3253091,25.5072 19.6387636,25.1922909 L27.6831273,17.1486545 C28.3114909,16.5202909 28.3114909,15.5021091 27.6831273,14.8737455 C27.0554909,14.2453818 26.0358545,14.2453818 25.4082182,14.8737455 L18.5013091,21.7799273 Z"
                id="Fill-3"
                fill="#2C97D1"
              />
              <g transform="translate(0.000000, 0.066255)" fill="#2C97D1">
                <path
                  d="M37.0030545,18.3578909 C36.1150545,18.3578909 35.3943273,19.0786182 35.3943273,19.9666182 C35.3943273,28.8378909 28.1768727,36.0553455 19.3063273,36.0553455 C10.4350545,36.0553455 3.2176,28.8378909 3.2176,19.9666182 C3.2176,11.0960727 10.4350545,3.87861818 19.3063273,3.87861818 C23.6248727,3.87861818 27.6779636,5.56734545 30.7201455,8.63207273 C31.3463273,9.26334545 32.3645091,9.26698182 32.9957818,8.6408 C33.6263273,8.01461818 33.6306909,6.99643636 33.0037818,6.36589091 C29.3536,2.68734545 24.4888727,0.661163636 19.3063273,0.661163636 C8.66050909,0.661163636 0.000145454545,9.32152727 0.000145454545,19.9666182 C0.000145454545,30.6124364 8.66050909,39.2728 19.3063273,39.2728 C29.9514182,39.2728 38.6117818,30.6124364 38.6117818,19.9666182 C38.6117818,19.0786182 37.8910545,18.3578909 37.0030545,18.3578909"
                  id="Fill-5"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  ),

  /**
   * Cellphone illustration
   */
  cellphone: (
    <g id="Editar-Perfil" fillRule="evenodd" fill="none">
      <g id="Assinante-(Confirmação-Upgrade)" transform="translate(-980 -422)">
        <g id="icone-agendamento" transform="translate(980 422)">
          <g id="Group-3" transform="translate(0 .019130)">
            <path
              id="Fill-1"
              fill="#E8F2F8"
              d="m69.333 80h-58.666c-5.867 0-10.667-4.853-10.667-10.784v-58.432c0-5.9322 4.8-10.784 10.667-10.784h58.666c5.867 0 10.667 4.8518 10.667 10.784v58.432c0 5.931-4.8 10.784-10.667 10.784"
            />
            <g id="Group-2" transform="translate(19 13)">
              <g id="Group-11">
                <path
                  id="Fill-4"
                  fill="#3E4E57"
                  d="m23.988 52.995h-18.534c-2.7806 0-5.0552-2.273-5.0552-5.054l-0.00004-37.069c0-2.7797 2.2746-5.0543 5.0547-5.0543h18.534c2.78 0 5.054 2.2746 5.054 5.0543v37.069c0 2.781-2.274 5.054-5.054 5.054"
                />
                <path
                  id="Fill-6"
                  fill="#DFE3E8"
                  d="m21.777 44.489h-14.046c-2.3163 0-4.2119-1.896-4.2119-4.213v-27.14c0-2.317 1.8956-4.2127 4.2123-4.2127h14.046c2.317 0 4.212 1.8957 4.212 4.2127v27.14c0 2.317-1.895 4.213-4.212 4.213"
                />
                <path
                  id="Fill-8"
                  fill="#2C97D1"
                  d="m18.767 0c-2.442 0-4.44 1.9983-4.44 4.4406v18.305 6.498c0 0.788 0.903 1.232 1.526 0.753l2.711-2.085c0.804-0.618 1.79-0.953 2.805-0.953h16.25c2.442 0 4.44-1.999 4.44-4.441v-18.077c0-2.4427-1.998-4.441-4.44-4.441h-18.852z"
                />
                <path
                  id="Fill-3"
                  fill="#fff"
                  d="m26.042 14.743l-3.179-3.179c-0.692-0.692-1.816-0.692-2.507 0-0.692 0.692-0.692 1.814 0 2.507l4.432 4.432c0.347 0.347 0.8 0.52 1.254 0.52 0.453 0 0.908-0.173 1.253-0.52l8.866-8.8644c0.692-0.6924 0.692-1.8145 0-2.507s-1.816-0.6925-2.507 0l-7.612 7.6114z"
                />
                <path
                  id="Fill-10"
                  fill="#637381"
                  d="m16.877 48.994c0 1.173-0.95 2.124-2.123 2.124-1.172 0-2.123-0.951-2.123-2.124s0.951-2.123 2.123-2.123c1.173 0 2.123 0.95 2.123 2.123"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  ),
  /**
   * E-mail illustration
   */
  mail: (
    <g id="Editar-Perfil" fillRule="evenodd" fill="none">
      <g id="Assinante-(Confirmação-Upgrade)" transform="translate(-680 -422)">
        <g id="icone-email" transform="translate(680 422)">
          <g id="Group-3" transform="translate(0 .019130)">
            <path
              id="Fill-1"
              fill="#E8F2F8"
              d="m69.333 80h-58.666c-5.867 0-10.667-4.853-10.667-10.784v-58.432c0-5.9322 4.8-10.784 10.667-10.784h58.666c5.867 0 10.667 4.8518 10.667 10.784v58.432c0 5.931-4.8 10.784-10.667 10.784"
            />
            <g id="email" fillRule="nonzero" transform="translate(17 17)">
              <path
                id="Shape"
                fill="#247AA8"
                d="m45.959 19.15l-2.585 1.915-3.83 2.776-15.416 11.394c-0.67 0.479-1.627 0.479-2.298 0l-15.415-11.394-2.8724-2.106-3.5426-2.585 3.5426-3.064 2.8725-2.49 15.32-13.117c0.383-0.28749 0.766-0.479 1.244-0.479 0.479 0 0.862 0.19151 1.245 0.47869l15.32 13.213 3.83 3.255 2.585 2.203z"
              />
              <path
                id="Shape"
                fill="#2C97D1"
                d="m24.128 35.139c-0.67 0.479-1.627 0.479-2.298 0l-21.83-15.989v24.894c0 1.053 0.86172 1.915 1.915 1.915h42.129c1.053 0 1.915-0.862 1.915-1.915v-24.894l-21.831 15.989z"
              />
              <path
                id="Shape"
                fill="#fff"
                d="m43.374 7.7556v13.213l-3.83 2.776-15.416 11.49c-0.67 0.479-1.627 0.479-2.298 0l-15.415-11.394-2.8724-2.106v-19.82c0-1.0533 0.8618-1.915 1.915-1.915h30.16l7.756 7.7556z"
              />
              <g id="Group" fill="#DFE3E8" transform="translate(8.0637)">
                <path
                  id="Shape"
                  d="m29.469 7.7556h5.841l-7.756-7.7556v5.8406c0 1.0533 0.862 1.915 1.915 1.915z"
                />
                <rect
                  id="Rectangle-path"
                  y="9.096"
                  x=".074773"
                  height="2.3936"
                  width="30.639"
                />
              </g>
              <rect
                id="Rectangle-path"
                height="2.3936"
                width="22.213"
                y="3.3511"
                x="8.1385"
                fill="#C4CDD5"
              />
              <rect
                id="Rectangle-path"
                height="2.3936"
                width="30.639"
                y="14.649"
                x="8.1385"
                fill="#DFE3E8"
              />
              <rect
                id="Rectangle-path"
                height="2.3936"
                width="13.883"
                y="20.394"
                x="24.894"
                fill="#C4CDD5"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  ),

  /**
   * Calendar illustration
   */
  calendar: (
    <g id="Editar-Perfil" fillRule="evenodd" fill="none">
      <defs>
        <polygon
          id="path-1"
          points="0 0.73477 45.288 0.73477 45.288 48.205 0 48.205"
        />
      </defs>
      <g id="Assinante-(Confirmação-Upgrade)" transform="translate(-380 -422)">
        <g id="icone-agenda" transform="translate(380 422)">
          <g id="Group-3" fill="#E8F2F8" transform="translate(0 .019130)">
            <path
              id="Fill-1"
              d="m69.333 80h-58.666c-5.867 0-10.667-4.853-10.667-10.784v-58.432c0-5.9322 4.8-10.784 10.667-10.784h58.666c5.867 0 10.667 4.8518 10.667 10.784v58.432c0 5.931-4.8 10.784-10.667 10.784"
            />
          </g>
          <g id="Group-24" transform="translate(17 15)">
            <g id="Group-3" transform="translate(0 .29087)">
              <mask id="mask-2" fill="white">
                <use xlinkHref="#path-1" />
              </mask>
              <path
                id="Fill-1"
                fill="#2C97D1"
                mask="url(#mask-2)"
                d="m35.834 5.6784v-3.4052c0-0.8461-0.691-1.5384-1.538-1.5384l-4.246-0.00003c-0.846 0-1.539 0.69233-1.539 1.5384v3.4052h-11.734v-3.4052c0-0.8461-0.692-1.5384-1.539-1.5384l-4.247-0.00003c-0.846 0-1.5382 0.69233-1.5382 1.5384v3.4052h-6.4882c-1.6369 0-2.9651 1.3271-2.9651 2.9651l-0.00001282 36.596c0 1.637 1.3282 2.965 2.9651 2.965h39.358c1.638 0 2.965-1.328 2.965-2.965v-36.596c0-1.6385-1.327-2.9656-2.965-2.9656h-6.489z"
              />
            </g>
            <path
              id="Fill-4"
              fill="#fff"
              d="m37.283 43.001h-29.278c-1.6071 0-2.9096-1.303-2.9096-2.909v-25.719c0-1.607 1.3025-2.909 2.9097-2.909h29.278c1.606 0 2.909 1.302 2.909 2.909v25.719c0 1.606-1.303 2.909-2.909 2.909"
            />
            <path
              id="Fill-6"
              fill="#E3D0FF"
              d="m15.21 20.223h-4.191c-0.296 0-0.537-0.242-0.537-0.538v-3.291c0-0.296 0.241-0.538 0.537-0.538h4.191c0.297 0 0.538 0.242 0.538 0.538v3.291c0 0.296-0.241 0.538-0.538 0.538"
            />
            <path
              id="Fill-8"
              fill="#E3D0FF"
              d="m15.21 29.601h-4.191c-0.296 0-0.537-0.242-0.537-0.537v-3.292c0-0.295 0.241-0.537 0.537-0.537h4.191c0.297 0 0.538 0.242 0.538 0.537v3.292c0 0.295-0.241 0.537-0.538 0.537"
            />
            <path
              id="Fill-10"
              fill="#2C97D1"
              d="m15.21 38.609h-4.191c-0.296 0-0.537-0.243-0.537-0.538v-3.291c0-0.296 0.241-0.538 0.537-0.538h4.191c0.297 0 0.538 0.242 0.538 0.538v3.291c0 0.295-0.241 0.538-0.538 0.538"
            />
            <path
              id="Fill-12"
              fill="#2C97D1"
              d="m24.767 20.223h-4.191c-0.296 0-0.537-0.242-0.537-0.538v-3.291c0-0.296 0.241-0.538 0.537-0.538h4.191c0.295 0 0.537 0.242 0.537 0.538v3.291c0 0.296-0.242 0.538-0.537 0.538"
            />
            <path
              id="Fill-14"
              fill="#E3D0FF"
              d="m24.767 29.601h-4.191c-0.296 0-0.537-0.242-0.537-0.537v-3.292c0-0.295 0.241-0.537 0.537-0.537h4.191c0.295 0 0.537 0.242 0.537 0.537v3.292c0 0.295-0.242 0.537-0.537 0.537"
            />
            <path
              id="Fill-16"
              fill="#2C97D1"
              d="m24.767 38.609h-4.191c-0.296 0-0.537-0.243-0.537-0.538v-3.291c0-0.296 0.241-0.538 0.537-0.538h4.191c0.295 0 0.537 0.242 0.537 0.538v3.291c0 0.295-0.242 0.538-0.537 0.538"
            />
            <path
              id="Fill-18"
              fill="#2C97D1"
              d="m34.269 20.223h-4.191c-0.296 0-0.537-0.242-0.537-0.538v-3.291c0-0.296 0.241-0.538 0.537-0.538h4.191c0.295 0 0.537 0.242 0.537 0.538v3.291c0 0.296-0.242 0.538-0.537 0.538"
            />
            <path
              id="Fill-20"
              fill="#2C97D1"
              d="m34.269 29.601h-4.191c-0.296 0-0.537-0.242-0.537-0.537v-3.292c0-0.295 0.241-0.537 0.537-0.537h4.191c0.295 0 0.537 0.242 0.537 0.537v3.292c0 0.295-0.242 0.537-0.537 0.537"
            />
            <path
              id="Fill-22"
              fill="#E3D0FF"
              d="m34.269 38.609h-4.191c-0.296 0-0.537-0.243-0.537-0.538v-3.291c0-0.296 0.241-0.538 0.537-0.538h4.191c0.295 0 0.537 0.242 0.537 0.538v3.291c0 0.295-0.242 0.538-0.537 0.538"
            />
          </g>
        </g>
      </g>
    </g>
  ),
  launch: (
    <g>
      <path d="M38 38h-28v-28h14v-4h-14c-2.21 0-4 1.79-4 4v28c0 2.21 1.79 4 4 4h28c2.21 0 4-1.79 4-4v-14h-4v14zm-10-32v4h7.17l-19.66 19.66 2.83 2.83 19.66-19.66v7.17h4v-14h-14z" />
      <path d="M0 0h48v48h-48z" fill="none" />
    </g>
  ),
  location_on: (
    <g>
      <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z" />
      <path d="M0 0h24v24H0z" fill="none" />
    </g>
  ),
  videocam: (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.5 5.25V3.5C8.5 3.225 8.275 3 8 3H2C1.725 3 1.5 3.225 1.5 3.5V8.5C1.5 8.775 1.725 9 2 9H8C8.275 9 8.5 8.775 8.5 8.5V6.75L10.5 8.75V3.25L8.5 5.25Z"
        fill="#1565C0"
      />
    </svg>
  ),
  warning: (
    <svg id="Outlined" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
      <g id="Fill">
        <path d="M29.68,25.6l-11-21.92a3,3,0,0,0-5.44,0L2.32,25.6A3,3,0,0,0,5,30H27a3,3,0,0,0,2.72-4.4Zm-1.84,1.91A1,1,0,0,1,27,28H5a1,1,0,0,1-.88-.49,1,1,0,0,1,0-1l11-21.93h0a1,1,0,0,1,1.86,0l11,21.93A1,1,0,0,1,27.84,27.51Z" />
        <rect height="9" width="2" x="15" y="11" />
        <circle cx="16" cy="24" r="2" />
      </g>
    </svg>
  ),
  money: (
    <svg
      width="10"
      height="10"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.99998 0.333344C3.31998 0.333344 0.333313 3.32001 0.333313 7.00001C0.333313 10.68 3.31998 13.6667 6.99998 13.6667C10.68 13.6667 13.6666 10.68 13.6666 7.00001C13.6666 3.32001 10.68 0.333344 6.99998 0.333344ZM7.93998 11.06V12.3333H6.15998V11.0467C5.01998 10.8067 4.05331 10.0733 3.97998 8.78001H5.28665C5.35331 9.48001 5.83331 10.0267 7.05331 10.0267C8.35998 10.0267 8.65331 9.37334 8.65331 8.96668C8.65331 8.41334 8.35998 7.89334 6.87331 7.54001C5.21998 7.14001 4.08665 6.46001 4.08665 5.09334C4.08665 3.94668 5.01331 3.20001 6.15998 2.95334V1.66668H7.93998V2.96668C9.17998 3.26668 9.79998 4.20668 9.83998 5.22668H8.53331C8.49998 4.48668 8.10665 3.98001 7.05331 3.98001C6.05331 3.98001 5.45331 4.43334 5.45331 5.07334C5.45331 5.63334 5.88665 6.00001 7.23331 6.34668C8.57998 6.69334 10.02 7.27334 10.02 8.95334C10.0133 10.1733 9.09998 10.84 7.93998 11.06Z"
        fill="#1565C0"
      />
    </svg>
  ),
};
