import * as React from 'react';
import generateClasses from 'app/shared/utils/generateClasses';

import css from './A.module.scss';

/** Component */
const A: React.SFC<AProps> = ({
  children,
  onClick,
  href,
  target,
  align,
  padding,
  bold,
  wrap,
  margin,
}) => (
  <a
    href={href}
    target={target}
    onClick={onClick}
    className={generateClasses(css, 'a', {
      align,
      padding,
      bold,
      wrap,
      margin,
    })}
  >
    {children}
  </a>
);

/** Definitions */
type AProps = {
  href?: string;
  wrap?: string;
  bold?: boolean;
  align?: string;
  margin?: string;
  padding?: string;
  children?: React.ReactNode;
  onClick?: (e: React.MouseEvent) => void;
  target?: '_blank' | '_parent' | '_self';
};

A.defaultProps = {
  bold: false,
  onClick: () => {},
};

export default A;
