/* eslint-disable react/prop-types, @typescript-eslint/indent */
import * as React from 'react';
import { Label, Input, Caption } from 'app/components';
import generateClasses from 'app/shared/utils/generateClasses';

import css from './Field.module.scss';

/** Component */
const Field: React.SFC<FieldProps> = ({
  onChange,
  onBlur,
  onFocus,
  onKeyUp,
  value,
  type,
  kind,
  name,
  message,
  label,
  placeholder,
  group,
  mask,
  maxLength,
  readOnly,
  autoFocus,
}) => (
  <div className={generateClasses(css, 'form-field', { kind })}>
    {label && (
      <Label kind={kind} for={name}>
        {label}
      </Label>
    )}
    <Input
      autoFocus={autoFocus}
      id={name}
      kind={kind}
      name={name}
      type={type}
      mask={mask}
      value={value}
      group={group}
      onBlur={onBlur}
      onFocus={onFocus}
      onKeyUp={onKeyUp}
      onChange={onChange}
      readOnly={readOnly}
      placeholder={placeholder}
      maxLength={maxLength}
    />
    {message && (
      <Caption padding="p-xs" kind={kind || 'subdued'}>
        {message}
      </Caption>
    )}
  </div>
);

/** Definitions */
type FieldProps = {
  autoFocus?: boolean;
  kind?: string;
  name?: string;
  group?: string;
  label?: string;
  message?: string;
  readOnly?: boolean;
  placeholder?: string;
  value?: string | number;
  maxLength?: string;
  onBlur?: (e: Event) => void;
  onKeyUp?: (e: KeyboardEvent) => void;
  onFocus?: (e: React.FocusEvent) => void;
  onChange?: (e: React.ChangeEvent) => void;
  mask?: any[] | ((...args: any[]) => any);
  type?:
    | 'tel'
    | 'url'
    | 'date'
    | 'file'
    | 'text'
    | 'time'
    | 'week'
    | 'color'
    | 'email'
    | 'image'
    | 'month'
    | 'radio'
    | 'range'
    | 'reset'
    | 'button'
    | 'hidden'
    | 'number'
    | 'search'
    | 'submit'
    | 'checkbox'
    | 'password'
    | 'datetime-local';
};

Field.defaultProps = {
  autoFocus: false,
  value: '',
  type: 'text',
  readOnly: false,
  onBlur: () => {},
  onFocus: () => {},
  onKeyUp: () => {},
  onChange: () => {},
};

export default Field;
