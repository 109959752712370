import * as React from 'react';
import icons from 'app/shared/paths/icons';
import generateClasses from 'app/shared/utils/generateClasses';

import css from './Icon.module.scss';

/** Component */
const Icon: React.SFC<IconProps> = ({ name, width, height, kind }) => (
  <svg
    preserveAspectRatio="xMidYMid meet"
    viewBox={`0 0 ${width} ${height || width}`}
    className={generateClasses(css, 'icon', { kind })}
  >
    {icons[name]}
  </svg>
);

/** Definitions */
type IconProps = {
  name: string;
  kind?: 'default' | 'info' | 'success' | 'warning' | 'subdued' | 'link';
  width?: number;
  height?: number;
};

Icon.defaultProps = {
  width: 24,
  kind: 'default',
};
export default Icon;
