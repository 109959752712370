import * as React from 'react';
import { connect } from 'react-redux';
import { Avatar, Flex, Col } from 'app/components';
import { avatarUpload, avatarDelete } from 'app/state/avatar/actions';

import UploadImage from './UploadImage';
import DeleteImage from './DeleteImage';
import css from './ImageUploadForm.module.scss';

/** Component */
const ImageUploadForm: React.SFC<ImageUploadFormProps> = (props) => (
  <div className={css['image-upload-form']}>
    <div className={css['image-upload-form-avatar']}>
      {props.is_uploading && (
        <div className={css['image-upload-form-avatar-uploading']}>
          <div />
          <div />
          <div />
          <div />
        </div>
      )}
      <Avatar imageUrl={props.src || props.imageUrl} size="3xl" />
    </div>
    <div className={css['image-upload-form-commands']}>
      <Flex>
        <Col margin="m-right">
          <UploadImage onLoad={props.upload} />
        </Col>
        <Col>
          <DeleteImage onDelete={props.delete} />
        </Col>
      </Flex>
    </div>
  </div>
)

/** Definitions */
type ImageUploadFormProps = {
  src?: string;
  imageUrl?: string;
  is_uploading?: boolean;
  delete: (e: React.MouseEvent) => void;
  upload: (obj: { image: { file: {}; src: string | ArrayBuffer | null }; form: FormData }) => void;
};

ImageUploadForm.defaultProps = {
  imageUrl: '',
};

export default connect(
  state => state.avatar,
  dispatch => ({
    delete: () => dispatch(avatarDelete()),
    upload: data => dispatch(avatarUpload(data)),
  }),
)(ImageUploadForm);
