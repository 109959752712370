import * as React from 'react';
import { Flex, Col, Heading, H3 } from 'app/components';

import css from './ContactItem.module.scss';

/** Component */
const ContactItem: React.SFC<ContactItemProps> = ({ title, content, href, icon }) => (
  <Flex margin="m-y" padding="p-right-lg">
    <Col auto margin="m-right">
      <div className={css['contact-item-icon']}>{icon()}</div>
    </Col>
    <Col auto margin="m-right">
      <Flex direction="col">
        <Heading>{title}</Heading>
        <H3 size="body">
          <a
            href={href}
            target="_blank"
            rel="noopener noreferrer"
            className={css['contact-item-link']}
          >
            {content}
          </a>
        </H3>
      </Flex>
    </Col>
  </Flex>
);

/** Definitions */
interface ContactItemProps {
  href: string;
  title: string;
  content: string;
  icon: () => React.ReactNode;
}

export default ContactItem;
