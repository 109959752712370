// Internal
import { AVATAR } from './types';

export const avatarUpload = (data: {}): { type: string; payload: { data: {} } } => ({
  type: AVATAR.UPLOAD,
  payload: { data },
});

export const avatarFinish = (src: string): { type: string; payload: { src: string } } => ({
  type: AVATAR.FINISH,
  payload: { src },
});

export const avatarDelete = (): { type: string } => ({
  type: AVATAR.DELETE,
});

export const avatarRemove = (): { type: string } => ({
  type: AVATAR.REMOVE,
});

export const avatarError = (): { type: string } => ({
  type: AVATAR.ERROR,
});
