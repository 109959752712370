import * as React from 'react';
import { H3 } from 'app/components';

import generateClasses from 'app/shared/utils/generateClasses';

import css from './Tag.module.scss';

/** Component */
const Tag: React.SFC<TagProps> = ({ children, kind, bullet }) => (
  <div className={generateClasses(css, 'tag', { kind })}>
    <span className={css['tag-bullet']}>{bullet || '●'}</span>
    <H3 size="body">
      <span className={css['tag-label']}>{children}</span>
    </H3>
  </div>
);

/** Definitions */
type TagProps = {
  kind?: string;
  bullet?: string;
  children?: React.ReactNode;
};

Tag.defaultProps = {
  children: null,
  kind: 'default',
  bullet: undefined,
};

export default Tag;
