import * as React from 'react';
import { Icon } from 'app/components';
import generateClasses from 'app/shared/utils/generateClasses';

import css from './Message.module.scss';

/** Component */
const Message: React.SFC<MessageProps> = ({ children, kind }) => (
  <div className={generateClasses(css, 'message', { kind })}>
    <div className={css['message-left-icon']}>
      <div className={css['message-icon-left-wrapper']} />
      {kind === 'error' && <Icon name="error" />}
      {kind === 'success' && <Icon name="success" />}
    </div>
    <div className={css['message-body']}>{children}</div>
    <div className={css['message-right-icon']} />
  </div>
);

/** Definitions */
type MessageProps = {
  kind?: 'error' | 'success';
  children?: React.ReactNode;
};

Message.defaultProps = {
  children: null,
};

export default Message;
