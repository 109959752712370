import * as React from 'react';
import { Flex, Col } from 'app/components';
import { FragmentChild } from '@types';

import css from './List.module.scss';
import { ListInterface } from './typings';

/** Component */
const List: ListInterface<ListProps> = ({ children, bullet }) => {
  const Items = (React.Children.toArray(children) as FragmentChild[])
    .filter((child) => child.type.displayName === 'Item')
    .map((item: any) => item.props.children);

  return (
    <Flex direction="col">
      {Items.map(item => (
        <Flex margin="m-bottom" key={`list-${item.id}-${Math.random()}`}>
          <Col auto margin="m-right">
            <div className={css['list-bullet']}>{bullet}</div>
          </Col>
          <Col>{item}</Col>
        </Flex>
      ))}
    </Flex>
  );
};

/** Fragments */
List.Item = ({ children }) => children;
List.Item.displayName = 'Item';

/** Definitions */
type ListProps = {
  bullet?: string;
  children?: React.ReactNode;
};

List.defaultProps = {
  children: null,
};

export default List;
