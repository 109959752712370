import * as React from 'react';
import generateClasses from 'app/shared/utils/generateClasses';

import css from './Label.module.scss';

/** Component */
const Label: React.SFC<LabelProps> = props => (
  <label htmlFor={props.for} className={generateClasses(css, 'label', { kind: props.kind })}>
    {props.children}
  </label>
);

/** Definitions */
type LabelProps = {
  for?: string;
  kind?: 'subdued' | 'error';
  children?: React.ReactNode;
};

export default Label;
