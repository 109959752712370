import * as React from 'react';
import autobind from 'autobind-decorator';
import ClickOutside from 'app/shared/components/ClickOutside';
import extractFragments from 'app/shared/utils/extractFragments';

import { DropdownInterface, DropdownFragmentsInterface } from './typings';

/** Component */
class Dropdown extends DropdownInterface<DropdownProps, DropdownState> {
  static defaultProps = {
    children: null,
  };

  state = {
    is_visible: false,
  };

  @autobind
  toggle(): void {
    if (this.state.is_visible) {
      this.close();
    } else {
      this.open();
    }
  }

  @autobind
  open(): void {
    this.setState({ is_visible: true });
  }

  @autobind
  close(): void {
    this.setState({ is_visible: false });
  }

  render(): React.ReactNode {
    const { Trigger, Menu }: DropdownFragmentsInterface = extractFragments(this.props.children);
    return (
      <ClickOutside onClickOutside={this.close}>
        {Trigger && React.cloneElement(Trigger, { onToggle: this.toggle })}
        {Menu && this.state.is_visible && React.cloneElement(Menu, { onClose: this.close })}
      </ClickOutside>
    );
  }
}

/** Fragments */
Dropdown.Menu = ({ children, onClose }) => children({ onClose });
Dropdown.Menu.displayName = 'Menu';

Dropdown.Trigger = ({ children, onClick, onToggle }) => children({ onClick, onToggle });
Dropdown.Trigger.displayName = 'Trigger';

/** Definitions */
export type DropdownState = {
  is_visible: boolean;
};

export type DropdownProps = {
  children?: React.ReactNode;
};

export default Dropdown;
