/* eslint-disable react/prop-types, @typescript-eslint/indent */
import * as React from 'react';
import MaskedInput from 'react-text-mask';
import generateClasses from 'app/shared/utils/generateClasses';

import css from './Input.module.scss';

/** Component */
const Input: React.SFC<InputProps> = ({
  name,
  type,
  mask,
  kind,
  group,
  value,
  onBlur,
  onFocus,
  onKeyUp,
  onChange,
  readOnly,
  maxLength,
  placeholder,
  autoFocus,
}) => (
  <>
    {mask && mask.length > 0 ? (
      <MaskedInput
        id={name}
        name={name}
        type={type}
        mask={mask}
        value={value}
        guide={false}
        onBlur={onBlur}
        onFocus={onFocus}
        onKeyUp={onKeyUp}
        readOnly={readOnly}
        onChange={onChange}
        maxLength={maxLength}
        placeholder={placeholder}
        className={generateClasses(css, 'input', { kind, group })}
      />
    ) : (
      <input
        autoFocus={autoFocus}
        id={name}
        name={name}
        type={type}
        value={value}
        onBlur={onBlur}
        onFocus={onFocus}
        onKeyUp={onKeyUp}
        onChange={onChange}
        readOnly={readOnly}
        maxLength={maxLength}
        placeholder={placeholder}
        className={generateClasses(css, 'input', { kind, group })}
      />
    )}
  </>
);

/** Definitions */
type InputProps = {
  autoFocus?: boolean;
  kind?: string;
  name?: string;
  group?: string;
  readOnly?: boolean;
  maxLength?: string;
  placeholder?: string;
  value?: string | number;
  mask?: any[] | ((event) => any);
  onBlur?: (event: React.FocusEvent) => any;
  onFocus?: (event: React.FocusEvent) => any;
  onKeyUp?: (event: React.KeyboardEvent) => any;
  onChange?: (event: React.ChangeEvent) => any;
  type?:
    | 'tel'
    | 'url'
    | 'date'
    | 'file'
    | 'text'
    | 'time'
    | 'week'
    | 'color'
    | 'email'
    | 'image'
    | 'month'
    | 'radio'
    | 'range'
    | 'reset'
    | 'button'
    | 'hidden'
    | 'number'
    | 'search'
    | 'submit'
    | 'checkbox'
    | 'password'
    | 'datetime-local';
};

Input.defaultProps = {
  autoFocus: false,
  mask: [],
  value: '',
  type: 'text',
  readOnly: false,
  onBlur: () => {},
  onKeyUp: () => {},
  onFocus: () => {},
  onChange: () => {},
};

export default Input;
