import * as React from 'react';
import generateClasses from 'app/shared/utils/generateClasses';

import css from './Heading.module.scss';

/** Component */
const Heading: React.SFC<HeadingProps> = ({ children, kind, align, padding, margin }) => (
  <div
    className={generateClasses(css, 'heading', {
      kind,
      align,
      padding,
      margin,
    })}
  >
    {children}
  </div>
);

/** Definitions */
export type HeadingProps = {
  kind?: string;
  align?: string;
  margin?: string;
  padding?: string;
  children?: React.ReactNode;
};

export default Heading;
