import * as React from 'react';
import generateClasses from 'app/shared/utils/generateClasses';

import css from './ButtonLink.module.scss';

/** Component */
const ButtonLink: React.SFC<ButtonLinkProps> = ({
  kind,
  children,
  disabled,
  link,
  target
}) => (
  <a href={link} className={generateClasses(css, 'link', { kind, disabled })} target={target}>
    {children}
  </a>
);

/** Definitions */
type ButtonLinkProps = {
  disabled?: boolean;
  link: string;
  target: string,
  children?: React.ReactNode;
  kind?: 'success' | 'default';
};

ButtonLink.defaultProps = {
  children: null,
  target: '_blank',
  disabled: false,
  kind: 'default',
};

export default ButtonLink;
