// External
import React from 'react';
import uuid from 'uuid/v4';

import './Switch.module.scss';

type SwitchProps = {
  value: boolean;
  name: string;
  onClick?: (e: {}, callback: () => void) => void;
  onChange?: () => void;
};

export default function Switch({
  value,
  name,
  onClick,
  onChange,
}: SwitchProps): JSX.Element {
  const id = uuid();
  const checkOnclickSubmit = () => {
    if (onClick) {
      onClick(
        {
          preventDefault: () => {},
          target: { value: !value, name },
        },
        onChange || (() => {}),
      );
    }
  };
  return (
    <div className="custom-control custom-switch">
      <input
        type="checkbox"
        id={id}
        checked={value}
        className="custom-control-input"
        name={name}
        onChange={checkOnclickSubmit}
      />
      <label className="custom-control-label" htmlFor={id} />
    </div>
  );
}
