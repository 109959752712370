import * as React from 'react';
import extractFragments from 'app/shared/utils/extractFragments';

import { NavbarInterface, NavbarFragmentsTypes } from './typings';
import css from './Navbar.module.scss';


/** Component */
const Navbar: NavbarInterface<{}> = ({ children }) => {
  const { Left, Right }: NavbarFragmentsTypes = extractFragments(children);
  return (
    <div className={css.navbar}>
      <div className={css['navbar-left']}>{Left}</div>
      <div className={css['navbar-right']}>{Right}</div>
    </div>
  );
};

/** Fragments */
Navbar.Left = ({ children }) => children;
Navbar.Left.displayName = 'Left';

Navbar.Right = ({ children }) => children;
Navbar.Right.displayName = 'Right';

/** Definitions */
Navbar.defaultProps = {
  children: null,
};

export default Navbar;
