// External
import React from 'react';
import Icon from '../Icon';

// Internal
import css from './TopBarAlert.module.scss';

type ExtraAction = {
  label: string,
  action: () => void,
}

type TopBarAlertProps = {
  isLoading: boolean;
  kind: string;
  text: string;
  mainActionLabel: string;
  mainAction: () => void;
  extraActions: ExtraAction[]
};

export default function TopBarAlert(
  {
    isLoading,
    kind,
    text,
    mainAction,
    mainActionLabel,
    extraActions = [],
  }: TopBarAlertProps,
): JSX.Element {
  return (
    <div className={`${css['topbar-alert-container']} ${css[kind]}`}>
      <div className={css['topbar-alert-content']}>
        <span className={`${css['topbar-alert-content-icon']} ${css[kind]}`}>
          <Icon name={kind} />
        </span>
        {text}
        <div className={css['topbar-actions']}>
          <>
            {extraActions.length > 0 && (
              extraActions.map((extra) => (
                <p className={css['extra-action']} onClick={() => extra.action()}>{extra.label}</p>
              ))
            )}
          </>
          <p className={css['main-action']} onClick={() => mainAction()}>{isLoading ? '...' : mainActionLabel}</p>
        </div>
      </div>
    </div>
  );
}
