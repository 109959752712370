import * as React from 'react';
import generateClasses from 'app/shared/utils/generateClasses';
import { avatar } from 'app/shared/paths/images';

import css from './Avatar.module.scss';

/** Definitions */
interface AvatarProps {
  rounded?: boolean;
  title?: string;
  imageUrl?: string;
  titleHover?: string;
  backgroundSize?: 'auto' | 'contain' | 'cover' | 'initial' | 'inherit' | 'unset' | number;
  size?: 'sm' | 'md' | 'lg' | '3xl';
  onClick?: (e: React.MouseEvent) => void;
}

/** Component */
const Avatar: React.SFC<AvatarProps> = ({
  size = 'md',
  rounded = true,
  backgroundSize = 'cover',
  title,
  imageUrl,
  titleHover,
  onClick,
}: AvatarProps) => {
  const backgroundImage = imageUrl && imageUrl.length > 0 ? `${imageUrl}` : `${avatar}`;
  const backgroundSizeStyle =
    typeof backgroundSize === 'number' ? `${backgroundSize}%` : backgroundSize;

  return (
    <div
      role="button"
      tabIndex={-1}
      onClick={onClick}
      className={generateClasses(css, 'avatar', { rounded, size })}
    >
      <img src={backgroundImage} alt={title} />
      {!!titleHover && <div className={css.title}>{titleHover}</div>}
    </div>
  );
};

export default Avatar;
